<template>
    <nav class="d-flex justify-content-between navbar navbar-expand-lg navbar-light bg-primary">
      <div class="d-flex align-items-center ">
        <b-button style="margin-left: 50px;" class=" button btn btn-light my-3" v-b-toggle.sidebar-1>
          <span class="navbar-light navbar-toggler-icon"></span>
        </b-button>
           <img style="width: 10%" src="../assets/icone.png" alt="">
          <Welcome :name="user_info.user" :company="'Painel do Posto: ' + user_info.cod + ' - ' + user_info.station"/>
      </div>
      
      <div>
        <b-sidebar id="sidebar-1" title="Pagposto" >
          <div class="">
            <ul>
               <li>
                <a class="menu-link" href="#" @click="redirectContracts()">
                  Contratos 
                </a>
              </li>
              <li>
                <a class="menu-link" href="#" @click="redirectAttendent()">
                  Frentistas
                </a>
              </li>
             
            </ul>
          </div>
          
        </b-sidebar>
      </div>

    <a @click="logout()" class="out" href="#">Sair</a>
</nav>
</template>
<style scoped>
#sidebar-1 ul, li, a {
  list-style-type: none;
  text-decoration: none;
  text-align: left;
  width: 100%;
  color: rgb(48, 48, 48);
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 5px;


}
.menu-link {
  padding-right: 500px;
  padding-top: 10px;
}
#sidebar-1 li {
border-bottom: 1px solid rgb(221, 221, 221);

}
#sidebar-1 li:hover {
border-bottom: 1px solid rgb(221, 221, 221);
background-color:rgb(230, 230, 230);
}

.out {
  width: 30px;
  margin: 0 30px;
  color: rgb(245, 250, 255);
}


</style>
<script>
import Welcome from '../components/Welcome.vue';
import axios from 'axios';
axios.defaults.headers.common = {'Authorization': "Bearer " + sessionStorage.getItem("token")}

export default{
    name:'NavBar',
      data(){
        return {
          station_id: this.$route.params.station_id,
          user_id: this.$route.params.user_id,
          user_info: {

          }
        }
        },
        components: {
          Welcome
        },
        methods: {
       
          redirectContracts() {
             this.$router.push('/station/' +  this.station_id + '/' + this.user_id)
          },
          redirectAttendent() {
             this.$router.push('/attendent/' + this.station_id + '/' + this.user_id)
          },
          logout(){
            sessionStorage.removeItem("token");
            this.$router.push('/');
          }
        
        },
        mounted(){
          this.$http.get('user/getUserAndStationByUserId/' + this.user_id).then(resposta => {
            //  console.log(resposta)
            if(resposta.data.status == "success"){
                this.user_info = resposta.data.data 
              }
           
          })
      }, 
     
    }
</script>
