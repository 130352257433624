<template>
  <div class="bg-light" id="app divContent" >
    <NavBarStation />
      <h2 class="p-3 d-flex justify-content-start">Relatório de Postos</h2>
    <PostoTabela />
      <h2 class="p-3 d-flex justify-content-start">Relatório de Frentistas</h2>
    <FrentistaTabela />
      <h2 class="p-3 d-flex justify-content-start">Relatório de Empresas</h2>
    <EmpresaTabela />
       <h2 class="p-3 d-flex justify-content-start">Relatório de Motoristas</h2>
    <MotoristaTabela />
      <h2 class="p-3 d-flex justify-content-start">Relatório de Veiculos</h2>
    <VeiculoTabela />
     <h2 class="p-3 d-flex justify-content-start">Relatório de Associações Veículo x Motorista </h2>
    <AssociacaoMotoristaTabela />
    <h2 class="p-3 d-flex justify-content-start">Relatório de Contratos </h2>
    <ContratoTabela />
      <h2 class="p-3 d-flex justify-content-start">Relatório de Saldos de Empresas </h2>
    <SaldoEmpresaTabela />
      <h2 class="p-3 d-flex justify-content-start">Relatório de Saldos de Veiculos </h2>
    <SaldoVeiculoTabela />
  </div>
</template>

<script>
import NavBarStation from '@/components/NavBarStation.vue'
import PostoTabela from '@/components/PostoTabela'
import EmpresaTabela from '@/components/EmpresaTabela'
import FrentistaTabela from '@/components/FrentistaTabela'
import MotoristaTabela from '@/components/MotoristaTabela'
import VeiculoTabela from '@/components/VeiculoTabela'
import AssociacaoMotoristaTabela from '@/components/AssociacaoMotoristaTabela'
import ContratoTabela from '@/components/ContratoTabela'
import SaldoEmpresaTabela from '@/components/SaldoEmpresaTabela'
import SaldoVeiculoTabela from '@/components/SaldoVeiculoTabela'

export default {
name: 'TheTables',
 components: {
    NavBarStation,
    PostoTabela,
    EmpresaTabela,
    FrentistaTabela,
    MotoristaTabela,
    VeiculoTabela,
    AssociacaoMotoristaTabela,
    ContratoTabela,
    SaldoEmpresaTabela,
    SaldoVeiculoTabela
  }
}
</script>

<style>
.height-div {
  min-height: 400px;
}
#divContent{
 overflow:auto; 
}

</style>