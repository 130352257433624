<template >
  <div class="height-div table-responsive shadow rounded bg-white m-3">
       <table class="m-3 table table-hover table-sm table-striped table-head-fixed text-nowrap">
            <thead>
                <tr class="align-middle">
                    <th >
                      Id
                    </th>
                     <th >
                        Cod. da Empresa
                    </th>
                     <th >
                        Empresa
                    </th>
                    <th >
                        Cod. do Posto
                    </th>
                    <th >
                        Posto
                    </th>
                    <th >
                        Status
                    </th>
                    <th >
                       Valor Contratado
                    </th>
                    <th >
                        Tipo de Contrato
                    </th>
                   <th >
                        Hora e Data
                    </th>
                   
                    <th >
                        Data de Início
                    </th>
                    <th >
                        Data Final
                    </th>
                    <th >
                        Observações
                    </th>
                </tr>
            </thead>
            <tbody >
                <tr v-for="(contrato, index) in contratos" :key="index" class="tablecontentfont table-format" >
                    <td>
                        {{contrato['id']}}
                    </td>
                    <td >
                        {{contrato['company_id']}}
                    </td>  
                    <td>
                        {{contrato['company_name']}}
                    </td>  
                     <td>
                        {{contrato['station_id']}}
                    </td>  
                     <td>
                        {{contrato['station_name']}}
                    </td>  
                     <td>
                        {{contrato['status']}}
                    </td>  
                     <td>
                        {{contrato['contracted_amount']}}
                    </td>  
                     <td>
                        {{contrato['amount_type']}}
                    </td>  
                     <td>
                        {{contrato['datetime']}}
                    </td>  
                     <td>
                        {{contrato['closed_date']}}
                    </td>  
                     <td>
                        {{contrato['initial_date']}}
                    </td>  
                     <td>
                        {{contrato['comments']}}
                    </td>  
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: 'ContratoTabela',
    data() {
        return {
            contratos: {

            }
        }
    },
    mounted(){
         this.$http.get('station/getAllContracts').then(resposta => {
         
           if(resposta.data.status == "success"){
                this.contratos = resposta.data.data
            }
        })
    }

}
</script>

<style>

</style>