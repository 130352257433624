<template>
  <div  class="bg-light"  id="app">
    <NavBar />
    
    <Dashboard/>
    <FooterApp/>
    <br/>
    <br/>
    <br/>
    
  </div>
</template>

<script>
import FooterApp from '@/components/FooterApp.vue'
import NavBar from '@/components/NavBar.vue'
import Dashboard from '@/components/Dashboard.vue'



export default {
  name: 'App',
  components: {
    NavBar,
    Dashboard,
    FooterApp
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>