<template>
    <div class="container ">
        <div class=" mt-3 d-flex flex-row  justify-content-between">
            <h3 class="px-2 pt-2 card-title">Cadastre um novo veículo:</h3>
        </div>
      <div class="shadow p-3 my-4 rounded bg-white">
        <div v-if="this.dataResponse.data.status == 'success'"  class="alert alert-success" role="alert">
            {{this.dataResponse.data.data}}
        </div>
          <div v-if="this.dataResponse.data.status == 'erro'"  class="alert alert-danger" role="alert">
            {{this.dataResponse.data.data}}
        </div>
        
        <!-- <form class="p-3"> -->
          
            <div class="row">
                <div class="my-2 d-flex flex-column col-md-4">
                    <label class="d-flex justify-content-start" for="inputEmail4">Placa:</label>
                    <input  v-mask="'NNNNNNN'" required v-model="newVehicle.plate" type="email" class="form-control form-control-sm" id="inputEmail4" placeholder="Placa do veículo">
                </div>
                <div class="my-2 d-flex flex-column col-md-4">
                    <label class="d-flex justify-content-start"  for="inputPassword4">Tipo de veículo:</label>
                    <select v-model="newVehicle.vehicle_type"  class="form-select form-select-sm">
                        <option selected >Automóvel, Pick up ou Utilitário leve</option>
                        <option>Caminhão</option>
                        <option>Motocicleta</option>
                    </select>
                </div> 
                <div class="my-2 d-flex flex-column col-md-4">
                    <label  class="d-flex justify-content-start"   for="inputEmail4">Marca:</label>
                    <input required v-model="newVehicle.brand" type="email" class="form-control form-control-sm" id="inputEmail4" placeholder="Marca do veículo">
                </div>    
                <div class="my-2 d-flex flex-column col-md-4">
                    <label class="d-flex justify-content-start" for="inputEmail4">Modelo:</label>
                    <input required v-model="newVehicle.model" type="email" class="form-control form-control-sm" id="inputEmail4" placeholder="Modelo do veículo">
                </div>
                <div class="my-2 d-flex flex-column col-md-4">
                    <label class="d-flex justify-content-start"  for="inputPassword4">UF:</label>
                    <select v-model="newVehicle.uf"  class="form-select form-select-sm">
                        <option selected>AC</option>
                        <option>AL</option>
                        <option>AP</option>
                        <option>AM</option>
                        <option>BA</option>
                        <option>CE</option>
                        <option>ES</option>
                        <option>GO</option>
                        <option>MA</option>
                        <option>MT</option>
                        <option>MS</option>
                        <option>MG</option>
                        <option>PA</option>
                        <option>PB</option>
                        <option>PR</option>
                        <option>PE</option>
                        <option>PI</option>
                        <option>RJ</option>
                        <option>RN</option>
                        <option>RS</option>
                        <option>RS</option>
                        <option>RO</option>
                        <option>RR</option>
                        <option>SC</option>
                        <option>SP</option>
                        <option>SP</option>
                        <option>SE</option>
                        <option>TO</option>
                        <option>DF</option>
                    </select>
                </div> 
                <div class="my-2 d-flex flex-column col-md-4">
                    <label  class="d-flex justify-content-start"   for="inputEmail4">Ano:</label>
                    <input v-mask="'####'" required v-model="newVehicle.year" type="text" class="form-control form-control-sm" id="inputEmail4" placeholder="Ano do veículo">
                </div>  
                <div class="my-2 d-flex flex-column col-md-4">
                    <label class="d-flex justify-content-start" for="inputEmail4">Nº Frota:</label>
                    <input required v-model="newVehicle.number_fleet" type="text" class="form-control form-control-sm" id="inputEmail4" placeholder="Número da Frota">
                </div>
                <div class="my-2 d-flex flex-column col-md-4">
                    <label class="d-flex justify-content-start"  for="inputPassword4">Tipo de combustivel:</label>
                    <select v-model="newVehicle.fuel"  class="form-select form-select-sm">
                        <option selected :value="1">Diesel</option>
                        <option :value="2">Gasolina</option>
                        <option :value="3">Gasolina Aditivada</option>
                        <option :value="4">Álcool</option>
                        <option :value="5">Flex</option>
                    </select>
                </div> 
                <div class="my-2 d-flex flex-column col-md-4">
                    <label class="d-flex justify-content-start" for="inputEmail4">Média de Consumo:</label>
                    <input required v-model="newVehicle.average_spend" type="text" v-mask="'###########'"  class="form-control form-control-sm" id="inputEmail4" placeholder="Média de Consumo">
                </div>
                  <div class="my-2 d-flex flex-column col-md-4">
                    <label class="d-flex justify-content-start" for="inputEmail4">Capacidate do tanque:</label>
                    <input required v-model="newVehicle.tank_capacity" type="text" v-mask="'###########'"   class="form-control form-control-sm" id="inputEmail4" placeholder="Capacidade do Tanque">
                </div>
                  <div class="my-2 d-flex flex-column col-md-4">
                    <label class="d-flex justify-content-start" for="inputEmail4">Hodômetro:</label>
                    <input required v-model="newVehicle.odometer"  type="text" v-mask="'###########'"  class="form-control form-control-sm" id="inputEmail4" placeholder="Hodômetro">
                </div>
                <div class="my-2 d-flex flex-column col-md-4">
                      <label class="d-flex justify-content-start" for="inputEmail4">Cor:</label>
                    <input required v-model="newVehicle.color" type="text" class="form-control form-control-sm" id="inputEmail4" placeholder="Cor do veículo">
                </div>
                  <div class="my-2 d-flex flex-column col-md-8">
                      <label class="d-flex justify-content-start" for="inputEmail4">Observações:</label>
                    <input required v-model="newVehicle.comments" type="text" class="form-control form-control-sm" id="inputEmail4" placeholder="Observações adicionais">
                </div>
                <div class="my-2 d-flex flex-column col-md-4">
                    <label class="d-flex justify-content-start" for="inputPassword4">Imagem do veículo:</label>
                   <div class="d-flex">
                        <img :src="previewImage" class="uploading-image" />
                        <div>
                           <input  type="file" accept="image/jpeg" @change=onFileSelected class="form-control form-control-sm" placeholder="Imagem do Veículo">
                        </div>
                    </div>
                </div>      
            </div>
           
            <div class="d-flex justify-content-end" ><button @click="register()"  class="m-3 btn btn-primary">Cadastrar</button></div>
           
        <!-- </form> -->
      </div>
    </div>
</template>
<style scoped>
 .uploading-image{
     display:flex;
     max-height: 100px ;
   }
</style>
<script>
import axios from 'axios';
axios.defaults.headers.common = {'Authorization': "Bearer " + sessionStorage.getItem("token")}
export default{
    name:'ContentListDriver',

    data(){
      return {
        name:'imageUpload',
        newVehicle:{
            company_id: this.$route.params.company_id,
            
        },

        dataResponse: {
            data:{
                data:''
            }
        },
        previewImage:null,
        selectedFile: null,
        
      }
    }, methods: {
        register() {
            this.$http.post('company/registerNewVehicle', this.newVehicle).then(response => {

              this.dataResponse = response
              if(this.dataResponse.data.status == "success") {
                this.onUpload();
                this.$router.push('/veiculo/' + this.$route.params.company_id + '/' + this.$route.params.user_id)
                    
              }

            })
        },
         onFileSelected(e){
            this.selectedFile = e.target.files[0]
            this.newVehicle.image =   this.selectedFile.name;
               
            },
            onUpload(){
                const fd = new FormData();
                fd.append('image', this.selectedFile, this.selectedFile.name);
                
               
                this.$http.post('company/registerVehicleImg', fd ,{headers: {'Content-Type': 'multipart/form-data'}}).then(response => {
                    if(response.data.status == "success") {
                        return true;
                    } else {
                        return false;
                    }
                  
                })

            }
    }
}

</script>