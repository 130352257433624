<template >
  <div class="height-div table-responsive shadow rounded bg-white m-3">
       <table class="m-3 table table-hover table-sm table-striped table-head-fixed text-nowrap">
            <thead>
                <tr class="align-middle">
                    <th >
                      Id
                    </th>
                     <th >
                        Saldo
                    </th>
                     <th >
                        Modelo
                    </th>
                    <th >
                        Cod. do Saldo de Empresa
                    </th>
                   
                </tr>
            </thead>
            <tbody >
                <tr v-for="(saldo, index) in saldos" :key="index" class="tablecontentfont table-format" >
                    <td>
                        {{saldo['id']}}
                    </td>
                    <td >
                        {{saldo['balance']}}
                    </td>  
                    <td>
                        {{saldo['model']}}
                    </td>  
                      <td>
                        {{saldo['contract_id']}}
                    </td>  
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: 'SaldoTabela',
    data() {
        return {
            saldos: {

            }
        }
    },
    mounted(){
         this.$http.get('company/getAllBalancesVehicles').then(resposta => {
         
           if(resposta.data.status == "success"){
                this.saldos = resposta.data.data
                console.log(this.saldos)
            }
        })
    }

}
</script>

<style>

</style>