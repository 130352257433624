<template>
 <div class="text-sm container-xxl my-4">
     <div class="text-sm container-xxl my-4">
            <div class="row justify-content-around">
                <div class="card-bg-0 text-light card bg-warning col-2">
                     <div class="align-items-start d-flex flex-column pt-2 pb-4">
                          <p>Saldo Total:</p>
                        <h2 ><money  v-model="saldoTotal" v-bind="money" type="hidden" ></money>{{this.saldoTotal}} L</h2>
                    </div>
                </div>
                <div class="card-bg-1 text-light card  bg-primary col-2">
                    <div class="align-items-start d-flex flex-column pt-2 pb-4">
                          <p>Contratos Ativos:</p>
                          <h2>{{this.contratosAtivos}}</h2>
                    </div>
                </div>
                <div class="card-bg-2 text-light card bg-danger col-2">
                  <div class="align-items-start d-flex flex-column pt-2 pb-4">
                        <p>Contratos Finalizados:</p>
                        <h2>{{this.contratosFinalizados}}</h2>
                    </div>
                </div>
                <div class="card-bg-3 text-light card bg-info col-2">
                  <div class="align-items-start d-flex flex-column pt-2 pb-4">
                        <p>Total de Contratos:</p>
                        <h2 ><money type="hidden"></money>{{this.contracts.length}}</h2>
                       
                    </div>
                </div>
                <div class="card-bg-4 text-light card  bg-success col-2">
                  <div class="align-items-start d-flex flex-column pt-2 pb-4">
                        <p>Qtd. Veículos:</p>
                        <h2>{{this.qtdCarros}}</h2>
                    </div>
                </div>
            </div>
        </div>
      <div class="table-responsive shadow rounded bg-white">
        <div class="border py-3 d-flex flex-row  justify-content-between">
          <h3 class="px-3 card-title">Contratos</h3>
        </div>
          <b-skeleton-wrapper :loading="loading">
             <template #loading>
              <b-card  style="border: none !important">
                <b-skeleton-table
                  :rows="5"
                  :columns="7"
                  :table-props="{ bordered: true, striped: true }"
                ></b-skeleton-table>
              </b-card>
            </template>
            <b-card style="min-height: 330px !important;overflow:auto; border: none !important" >
               <div v-if="this.error !== ''"  class="alert alert-danger" role="alert">
                {{this.error}}
              </div>
              <table v-if="this.error == ''"  class="table-sm table-hover table-bordered table table-striped">
                  <thead>
                        <tr>
                        <th scope="col">Cod.</th>
                        <th scope="col">Posto</th>
                        <th scope="col">Data Inicial</th>
                        <th scope="col">Data Final</th>
                        <th scope="col">Status</th>
                        <th scope="col">Saldo contratado</th>
                        <th scope="col">Saldo restante</th>
                        <th scope="col">Observações</th>
                        <th scope="col">Ação</th>
                      </tr>
                       <tr class="align-middle">
                       
                          <th>
                          <input class="form-control tablefont form-control-sm" type="text"  v-model="searchByCod" placeholder="Código">
                          </th>
                          <th><input class="form-control tablefont form-control-sm" type="text" v-model="searchByStation" placeholder="Empresa"></th>
                           <th>
                             <b-form-datepicker style="width: 150px" v-model="startDate" :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }" placeholder="De" id="datepicker-start" size="sm" locale="br" class=" tablefont mb-2"></b-form-datepicker>
                          </th>
                           <th >
                            <b-form-datepicker  style="width: 150px" v-model="endDate" :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }" placeholder="Até" id="datepicker-end" size="sm" locale="br" class=" tablefont mb-2"></b-form-datepicker>
                          </th>
                          <th>
                            <select v-model="searchByStatus"  placeholder="Combustível" class=" tablefont form-select form-select-sm" >
                              <option selected value="">Selecione</option>
                              <option value="0">Ativo</option>
                              <option value="1">Expirado</option>
                              <option value="2">Esgotado</option>
                            </select>
                          </th>
                          <th><input class="form-control tablefont form-control-sm" type="text" v-model="searchByContractedBalance" placeholder="Saldo Contratado"></th>
                          <th><input class="form-control tablefont form-control-sm" type="text" v-model="searchByCurrentBalance" placeholder="Saldo Restante"></th>
                          <th></th>
                          <th>
                            <button title="Limpar filtro" @click="limparFiltro()" v-b-tooltip.hover class="mx-2 tablefont btn btn-primary btn-sm">Limpar</button>
                          </th>
              
                      </tr>
                     
                    </thead>
                      <tbody>
            
                        <tr v-for="(contract, index) in contractsFiltered" :key="index">
                          <th >PAG-{{contract.contract_id}}</th>
                          <th >{{contract.station_name}}</th>
                          <td >{{contract.initial_date}}</td>
                          <td>{{contract.closed_date}}</td>
                          <td v-if="contract.status == 2" class="d-flex justify-content-start left-red">Esgotado</td>
                          <td v-if="contract.status == 1" class="d-flex justify-content-start left-yellow">Expirado</td>
                          <td v-if="contract.status == 0" class="d-flex justify-content-start left-green">Ativo</td>
                          <td v-if="contract.amount_type == 0" >R$ {{contract.contracted_amount}}</td>
                          <td v-if="contract.amount_type == 1" >{{contract.contracted_amount}} L</td>
                          <td v-if="contract.amount_type == 0" >R$ {{contract.balance}}</td>
                          <td v-if="contract.amount_type == 1" >{{contract.balance}} L </td>
                          <td>{{contract.comments}}</td>
                          
                          <td><a href="#" class="link-success" @click="redirectHitoric(contract.contract_id)" >Acessar</a></td>
                        </tr>
                        
                      </tbody>
                    </table>
            </b-card>
        </b-skeleton-wrapper>

       
      </div>
    
    </div>
    
</template>
<style scoped>

.left-yellow {
  border-left: 5px solid rgb(255, 174, 0);

}
.left-red {
  border-left: 5px solid rgb(255, 38, 0);

}
.left-green{
   border-left: 5px solid rgb(14, 169, 14);
}
.btn-x-sm{
  width: 74px !important;
  height: 25px !important;
  border-radius: 3px !important;
  padding-bottom: 22px !important;
  
   
}
</style>
<script>
import axios from 'axios';
axios.defaults.headers.common = {'Authorization': "Bearer " + sessionStorage.getItem("token")}

export default{
    name:'DashboardView',
    data(){
      return {
        userId: this.$route.params.user_id,
        idCompany: this.$route.params.company_id,
        contracts: [],
        error: '',
        loading: false,
        loadingTime: 0,
        maxLoadingTime: 2,
        money: {
          decimal: ',',
          thousands: '.',
          prefix: ' ',
          suffix: '',
          precision: 2,
          masked: true,
        },
        saldoTotal: 0,
        contratosAtivos: 0,
        contratosFinalizados: 0,
        qtdCarros: 0,
        cars: {},
           searchByCod:'',
        searchByStation:'',
        searchByStatus:'',
        startDate:'',
        endDate:'',
        searchByContractedBalance: '',
        searchByCurrentBalance: ''
      }
    },
     watch: {
      loading(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.clearLoadingTimeInterval()

          if (newValue) {
            this.$_loadingTimeInterval = setInterval(() => {
              this.loadingTime++
            }, 1000)
          }
        }
      },
      loadingTime(newValue, oldValue) {
        if (newValue !== oldValue) {
          if (newValue === this.maxLoadingTime) {
            this.loading = false
          }
        }
      }
    },
     created() {
      this.$_loadingTimeInterval = null
    },

    mounted(){
        this.startLoading(),
       this.$http.get('company/getContractByCompanyId/' + this.idCompany).then(resposta => {
          
          if(resposta.data.status == "success"){
            this.contracts = resposta.data.data;
            this.calculaSaldoTotal();
            this.calculaContratosAtivos();
            this.calculaContratosFinalizados()
          }
          if(resposta.data.status == "erro"){
            this.error = resposta.data.data;
          }  
        })
         this.$http.get('company/getAllVehiclesBycompanyId/' + this.idCompany).then(resposta => {
          if(resposta.data.status == "success"){
            this.cars = resposta.data.data;  
            this.qtdCarros =  this.cars.length; 
      
          }
         })
      
    },
     methods: {
      limparFiltro() {
        this.searchByCod = '',
        this.searchByStation = '',
        this.searchByStatus = '',
        this.searchByContractedBalance =  '',
        this.searchByCurrentBalance =  ''
        this.selected = null,
        this.startDate = null,
        this.endDate = null
        
      },
    
      clearLoadingTimeInterval() {
        clearInterval(this.$_loadingTimeInterval)
        this.$_loadingTimeInterval = null
      },
      startLoading() {
        this.loading = true
        this.loadingTime = 0
      },
      redirectHitoric(contract_id) {
             this.$router.push('/historico/' + this.idCompany + '/' + this.userId + '/' + contract_id)
      },
      calculaSaldoTotal(){
        var soma = 0
        for(var i = 0; i < this.contracts.length; i++) {
         soma += this.contracts[i].balance
        }
        return this.saldoTotal = soma;
      },
      calculaContratosAtivos(){
        var contratosAtivos = 0
        for(var i = 0; i < this.contracts.length; i++) {
        if(this.contracts[i].status == 0) {
          contratosAtivos++
        }
         this.contratosAtivos = contratosAtivos;
        }
        
      },
      calculaContratosFinalizados(){
        var contratosFinalizados = 0
        for(var i = 0; i < this.contracts.length; i++) {
           
        if(this.contracts[i].status != 0) {
          contratosFinalizados++
        }
         this.contratosFinalizados = contratosFinalizados;
        }
        
      },
      dateReplace(date) {
       let data = date.split('/');
       let newdata = data[1] + '/' + data[0] + '/' + data[2];
       return newdata;
      },
        localizeDate(date) {
       
        if (!date || !date.includes('-')) return date

        const [yyyy, mm, dd] = date.split('-')
        return new Date(`${mm}/${dd}/${yyyy}`)
      },
      
    },
     computed: {
       contractsFiltered() {
      
        
      let startDate = this.localizeDate(this.startDate);
      let endDate = this.localizeDate(this.endDate);
      
                
            var valores = [];
          
              
            valores = this.contracts.filter((contract) => {
              
              contract.contract_id = contract.contract_id.toString()
              contract.status =  contract.status.toString();
              contract.contracted_amount = contract.contracted_amount.toString()
              contract.balance = contract.balance.toString()

              const contractDate = contract.initial_date
              const finalDate = contract.closed_date
              const itemDateClosed = new Date(this.dateReplace(finalDate))
              const itemDate = new Date(this.dateReplace(contractDate))
            
              
            
              if (this.searchByCod || this.searchByStation || this.searchByStatus || this.searchByContractedBalance || this.searchByCurrentBalance ) {
               
                if(startDate && endDate) {
                 
                  return (
                      contract.contract_id.indexOf(this.searchByCod) > -1 &&
                      contract.station_name.toLowerCase().indexOf(this.searchByStation.toLowerCase()) > -1 &&
                      contract.status.indexOf(this.searchByStatus) > -1  &&
                      contract.contracted_amount.indexOf(this.searchByContractedBalance) > -1 &&
                      contract.balance.indexOf(this.searchByCurrentBalance) > -1 &&
                      startDate <= itemDate && itemDateClosed <= endDate
                    
                    
                  );
                }
                if(startDate && !endDate) {
                 
                  return(
                     contract.contract_id.indexOf(this.searchByCod) > -1 &&
                      contract.station_name.toLowerCase().indexOf(this.searchByStation.toLowerCase()) > -1 &&
                      contract.status.indexOf(this.searchByStatus) > -1  &&
                      contract.contracted_amount.indexOf(this.searchByContractedBalance) > -1 &&
                      contract.balance.indexOf(this.searchByCurrentBalance) > -1 &&
                      startDate <= itemDate
                    )
                
                }
                if(!startDate && endDate) {
                  return (
                    contract.contract_id.indexOf(this.searchByCod) > -1 &&
                    contract.station_name.toLowerCase().indexOf(this.searchByStation.toLowerCase()) > -1 &&
                    contract.status.indexOf(this.searchByStatus) > -1  &&
                    contract.contracted_amount.indexOf(this.searchByContractedBalance) > -1 &&
                    contract.balance.indexOf(this.searchByCurrentBalance) > -1 &&
                    itemDateClosed >= endDate
                    
                  );
                }

                return (
                  contract.contract_id.indexOf(this.searchByCod) > -1 &&
                    contract.station_name.toLowerCase().indexOf(this.searchByStation.toLowerCase()) > -1 &&
                    contract.status.indexOf(this.searchByStatus) > -1  &&
                    contract.contracted_amount.indexOf(this.searchByContractedBalance) > -1 &&
                    contract.balance.indexOf(this.searchByCurrentBalance) > -1 
                )
              }
              
              if(startDate && endDate) {
                
                  return (
                    contract.contract_id.indexOf(this.searchByCod) > -1 &&
                    contract.station_name.toLowerCase().indexOf(this.searchByStation.toLowerCase()) > -1 &&
                    contract.status.indexOf(this.searchByStatus) > -1  &&
                    contract.contracted_amount.indexOf(this.searchByContractedBalance) > -1 &&
                    contract.balance.indexOf(this.searchByCurrentBalance) > -1 &&
                    startDate <= itemDate && itemDateClosed <= endDate
                    
                  );
              }

              if(!startDate && endDate) {
                  return(
                      contract.contract_id.indexOf(this.searchByCod) > -1 &&
                      contract.station_name.toLowerCase().indexOf(this.searchByStation.toLowerCase()) > -1 &&
                      contract.status.indexOf(this.searchByStatus) > -1  &&
                      contract.contracted_amount.indexOf(this.searchByContractedBalance) > -1 &&
                      contract.balance.indexOf(this.searchByCurrentBalance) > -1 &&
                      startDate >= itemDateClosed
                    )
                
              }
              if(startDate && !endDate) {
                  return(
                      contract.contract_id.indexOf(this.searchByCod) > -1 &&
                      contract.station_name.toLowerCase().indexOf(this.searchByStation.toLowerCase()) > -1 &&
                      contract.status.indexOf(this.searchByStatus) > -1  &&
                      contract.contracted_amount.indexOf(this.searchByContractedBalance) > -1 &&
                      contract.balance.indexOf(this.searchByCurrentBalance) > -1 &&
                      startDate <= itemDate
                    )
                
              }
                 
              return (
                  contract.contract_id.indexOf(this.searchByCod) > -1 &&
                  contract.station_name.toLowerCase().indexOf(this.searchByStation.toLowerCase()) > -1 &&
                  contract.status.indexOf(this.searchByStatus) > -1  &&
                  contract.contracted_amount.indexOf(this.searchByContractedBalance) > -1 &&
                  contract.balance.indexOf(this.searchByCurrentBalance) > -1 

              );

  
             });
          
             return valores
          
        },
    }
     
  }
</script>