<template>
  <div class="bg-light" id="app">
    <NavBarStation />
    <HistoricList/>
    <!-- <FooterApp/> -->
  </div>
</template>

<script>
// import FooterApp from '@/components/FooterApp.vue'
import NavBarStation from '@/components/NavBarStation.vue'
import HistoricList from '@/components/StationHistoric.vue'



export default {
  name: 'App',
  components: {
    NavBarStation,
    HistoricList,
    // FooterApp
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
