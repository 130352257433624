<template>
   <div class="text-light user-box d-flex align-items-start justify-content-between flex-column">
    <span>
        Bem-vindo(a), {{name}}!
    </span>
    <span>
        {{company}}
    </span>
  </div>
</template>

<script>
export default {
    name: "WelcomeView",
    props:{
        name: String,
        company: String
    }

}
</script>

<style>
.user-box {
  height: 30%;
}

.user-box span:last-child {
 font-size: 0.8em;
}
.user-box span:first-child {
 font-weight: bolder;
}
</style>