<template>
  <div  class="text-sm container-xxl my-4">
    <div class="table-responsive shadow rounded bg-white">
      <div class="border py-3 d-flex flex-row  justify-content-between">
          <h3 class="px-3 pt-2 card-title">Frota de veículos</h3>
            <button type="button" @click="routerPush()" class="m-2 btn btn-sm btn-success">Novo veículo</button>
      </div>
      
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card style="border: none !important">
              <b-skeleton-table
                :rows="5"
                :columns="8"
                :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>
            </b-card>
          </template>
          <b-card style="border: none !important" >
            <div v-if="this.error !== ''"  class="alert alert-danger" role="alert">
              {{this.error}}
            </div>
            <table class="table-sm table-hover table-bordered table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Placa</th>
                  <th scope="col">Modelo</th>
                  <th scope="col">Saldo</th>
                  <th scope="col">Recarregar</th>
                  <th scope="col">Ação</th>
                </tr>
              </thead>
              <tbody>
                 <tr v-for="(car, index) in cars" :key="index">
                  <th scope="row">{{car.vehicle_id}}</th>
                  <td>{{car.plate}}</td>
                  <td>{{car.model}}</td>
                  <td>{{car.balance}}</td>
                  <td style="max-width:100px;">
                    <div class="input-group input-group-sm">
                      
                      <money  v-model="car.new_balance"  v-bind="money"  class="form-control" aria-label="Text input with dropdown button"></money>
                          <button v-if="car.status == 0" disabled class="btn btn-sm btn-success ">+</button>
                          <button v-if="car.status == 1"  @click="edit(car.vehicle_id)" class="btn btn-sm btn-success ">+</button>
                    </div>
                  </td>
                    <td v-if="car.status == 1" scope="col"><button @click="mudarStatusCarro(car.vehicle_id, car.status)" class="btn btn-warning btn-sm">Desativar</button></td>
                    <td v-if="car.status == 0" scope="col"><button @click="mudarStatusCarro(car.vehicle_id, car.status)" class="btn btn-success btn-sm">Ativar</button></td>
                </tr>
              </tbody>
            </table>
          </b-card>
    </b-skeleton-wrapper>
    </div>
    <div class=" my-5 d-flex flex-row-reverse">
      <br>
      <hr>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
axios.defaults.headers.common = {'Authorization': "Bearer " + sessionStorage.getItem("token")}

export default{
    name:'ContentList',
    data(){
      return {
       
        money: {
          decimal: ',',
          thousands: '.',
          prefix: ' ',
          suffix: '',
          precision: 2,
          masked: false
        },
        idCompany: this.$route.params.company_id,
        idUser: this.$route.params.user_id,
        data: { vehicle_id: '', company_id: this.$route.params.company_id, user_id: this.$route.params.user_id},
        cars: {
        },
        error: '',
        statusData: {},
        
      }
    },

    mounted(){
        this.$http.get('company/getAllVehiclesBycompanyId/' + this.idCompany).then(resposta => {
          if(resposta.data.status == "success"){
            this.cars = resposta.data.data;  
     
          }
           if(resposta.data.status == "erro") {
           this.error = resposta.data.data;
         }
        })
      },

    methods: {
      edit(id){
        
            Array.from(this.cars).forEach((car) => {
              if(car.new_balance) {
                    this.data.new_balance = car.new_balance;
              }
           
           })

                    
        
        this.data.vehicle_id = parseInt(id); 
       
        this.$http.post('recharge/addBalance', this.data).then(response => {
      
          if(response.data.status == "success") {
          
              this.$http.get('company/getAllVehiclesBycompanyId/' + this.idCompany).then(resposta => {
              this.cars = resposta.data.data;
              this.error = '';
              })
            } else if (response.data.status == "erro"){
                this.error = response.data.data;
               
            }
        })
      },

      routerPush() {
          this.$router.push('/cadastro_veiculo/' + this.idCompany + '/' + this.idUser)
      },

      mudarStatusCarro(id, status){
        this.statusData = {
          id: id,
          status: status
        }
        this.$http.post('company/changeStatusVehicleById/', this.statusData).then(resposta => {

     
          if(resposta.data.status == "success"){
                 this.$http.get('company/getAllVehiclesBycompanyId/' + this.idCompany).then(resposta => {
                    if(resposta.data.status == "success"){
                      this.cars = resposta.data.data;  
              
                    }
                    if(resposta.data.status == "erro") {
                    this.error = resposta.data.data;
                  }
                  })
     
          }
      })
      
     }
     
  }
}

</script>