<template>
  <div class="height-div table-responsive shadow rounded bg-white m-3">
       <table class="m-3 table table-hover table-sm table-striped table-head-fixed text-nowrap">
            <thead>
                <tr class="align-middle">
                    <th >
                      Id
                    </th>
                     <th >
                        Razão Social
                    </th>
                     <th >
                      Endereço
                    </th>
                     <th >
                      CNPJ
                    </th>
                     <th >
                      Telefone
                    </th>
                    <th>
                        Gerente
                    </th>
                    <th>
                        Latitude
                    </th>
                    <th>
                        Longitude
                    </th>
                     <th >
                      Status
                    </th>
                     <th >
                      Hora e data
                    </th>
                </tr>
            </thead>
            <tbody >
                <tr v-for="(posto, index) in postos" :key="index" class="tablecontentfont table-format" >
                    <td>
                        {{posto['id']}}
                    </td>
                    <td >
                        {{posto['name']}}
                    </td>  
                    <td>
                        {{posto['address']}}
                    </td>  
                    <td>
                        {{posto['cnpj']}}
                    </td>  
                    <td>
                        {{posto['phone']}}
                    </td>  
                    <td>
                        {{posto['manager']}}
                    </td>  
                    <td>
                        {{posto['latitude']}}
                    </td>  
                    <td>
                        {{posto['longitude']}}
                    </td>  
                    <td>
                        {{posto['status']}}
                    </td>  
                    <td>
                        {{posto['datetime']}}
                    </td> 
                    
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: 'PostoTabela',
    data() {
        return {
            postos: {

            }
        }
    },
    mounted(){

       this.$http.get('station/getAllStations').then(resposta => {
         
           if(resposta.data.status == "success"){
                this.postos = resposta.data.data
            }
            console.log(this.postos)
        
        
        })
    }

}
</script>

<style>

</style>