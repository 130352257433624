<template>
    <div class="container ">
        <div class=" my-3 d-flex flex-row  justify-content-between">
            <h3 class="px-2 pt-2 card-title">Cadastre um novo motorista:</h3>
        </div>
      <div class="shadow p-3 my-4 rounded bg-white">
        <div v-if="this.dataResponse.data.status == 'success'"  class="alert alert-success" role="alert">
            {{this.dataResponse.data.data}}
        </div>
          <div v-if="this.dataResponse.data.status == 'erro'"  class="alert alert-danger" role="alert">
            {{this.dataResponse.data.data}}
        </div>
        
        <!-- <form class="p-3"> -->
            <div class="p-2 d-flex">
                <div class="d-flex flex-column align-items-start form-group m-1 col-md-12">
                    <label for="inputEmail4">Nome Completo:</label>
                    <input v-model="driverEdit.name" type="email" class="form-control" id="inputEmail4" placeholder="Nome completo">
                </div>
                
            </div>
            <div class="p-2 d-flex">
                <div class="d-flex flex-column align-items-start form-group m-1 col-md-6">
                    <label for="inputEmail4">Telefone:</label>
                    <input v-mask="'(##) #####-####'" v-model="driverEdit.phone" type="email" class="form-control" id="inputEmail4" placeholder="Telefone">
                </div>
                <div class="d-flex flex-column align-items-start form-group m-1 col-md-6">
                    <label for="inputPassword4">CPF:</label>
                    <input v-mask="'###.###.###-##'" v-model="driverEdit.cpf" type="text" class="form-control" id="inputPassword4" placeholder="CPF">
                </div>       
            </div>
            <div class="p-2 d-flex">
                <div class="d-flex flex-column align-items-start form-group m-1 col-md-12">
                    <label for="inputEmail4">E-mail:</label>
                    <input v-model="driverEdit.email" type="email" class="form-control" id="inputEmail4" placeholder="E-mail">
                </div>                   
            </div>
             <div class="p-2 d-flex">
                <div class="d-flex flex-column align-items-start form-group m-1 col-md-6">
                    <label for="inputEmail4">Senha:</label>
                    <input v-model="driverEdit.password" type="password" class="form-control" id="inputEmail4" placeholder="Senha">
                </div>
                   
            </div>
            <div class="p-2 d-flex">
                <div class="d-flex flex-column align-items-start form-group m-1 col-md-12">
                    <label for="inputEmail4">Endereço:</label>
                    <input v-model="driverEdit.address"  type="email" class="form-control" id="inputEmail4" placeholder="Endereço">
                </div>
                <!-- <div class="d-flex flex-column align-items-start form-group m-1 col-md-2">
                    <label for="inputPassword4">Número:</label>
                    <input v-model="number" type="password" class="form-control" id="inputPassword4" placeholder="Password">
                </div>
                <div class="d-flex flex-column align-items-start form-group m-1 col-md-5">
                    <label for="inputPassword4">Bairro:</label>
                    <input v-model="bairro" type="password" class="form-control" id="inputPassword4" placeholder="Password">
                </div> -->
            </div>
            <button @click="register()"  class="m-3 btn btn-primary">Editar</button>
           
        <!-- </form> -->
      </div>
        <div class=" my-5 d-flex flex-row  justify-content-between">
      
      </div>
    </div>
</template>
<script>
    import axios from 'axios';
    axios.defaults.headers.common = {'Authorization': "Bearer " + sessionStorage.getItem("token")}
    export default{
        name:'ContentListDriver',

        data(){
        return {
            driverId: this.$route.params.driver_id,
            driverEdit:{
                company_id: this.$route.params.company_id,
                user_type: 3,
                
            },

            dataResponse: {
                data:{
                    data:''
                }
            }

            
        }
        }, methods: {
            register() { 
                this.$http.post('company/editDriverById', this.driverEdit).then(response => {

                this.dataResponse = response
                this.driverEdit = {}
                
                })
            }
        
        },  mounted(){
            
            this.$http.get('company/getDriverById/' + this.driverId ).then(resposta => {
                
            this.driverEdit = resposta.data.data[0]
        
            })
        
        }
    }

</script>