<template>
     <nav class="d-flex justify-content-between navbar navbar-expand-lg navbar-light bg-primary">
      
      <div class="d-flex align-items-center ">
        
        <b-button style="margin-left: 50px;" class=" button btn btn-light my-3" v-b-toggle.sidebar-1>
          <span class="navbar-light navbar-toggler-icon"></span>
        </b-button>
        <img style="width: 10%" src="../assets/icone.png" alt="">
        ]
        <Welcome :name="user_info.user" :company="'Painel da Empresa: ' + user_info.cod + ' - ' + user_info.company"/>
        <br>
      
      </div>
      <div>
        <b-sidebar id="sidebar-1" title="Pagposto" shadow>
          <div class="px-3 py-2">
            <ul>
              <li>
                <a href="#" @click="redirectDashboard()">
                  Dashboard
                </a>
              </li>
              <li>
                <a href="#" @click="redirectVehicle()">
                  Veículos
                </a>
              </li>
              <li>
                <a href="#" @click="redirectDriver()">
                  Motoristas
                </a>
              </li>

            </ul>
          </div>
        </b-sidebar>
      </div>

    <a @click="logout()" class="out" href="#">Sair</a>
</nav>
</template>
<style scoped>
#sidebar-1 ul, li, a {
  list-style-type: none;
  text-decoration: none;
  text-align: left;
  width: 100%;
  margin: 5px;

}
.out {
  width: 30px;
  margin: 0 30px;
  color: rgb(245, 250, 255);
}
</style>
<script>

import Welcome from '../components/Welcome.vue';
import axios from 'axios';
axios.defaults.headers.common = {'Authorization': "Bearer " + sessionStorage.getItem("token")}

export default{
    name:'NavBar',
      data(){
        return {
          company_id: this.$route.params.company_id,
          user_id: this.$route.params.user_id,
            user_info: {

          }
        }
        },
         components: {
          Welcome
        },
        methods: {
          redirectDriver() {
            this.$router.push('/motorista/' + this.company_id + '/' + this.user_id)
          },
          redirectVehicle() {
            this.$router.push('/veiculo/' + this.company_id + '/' + this.user_id)
          },
         
          redirectDashboard() {
             this.$router.push('/' + this.company_id + '/' + this.user_id)
          },
          logout(){
            sessionStorage.removeItem("token");
            this.$router.push('/');
          }
        },
         mounted(){
          this.$http.get('user/getUserAndCompanyByUserId/' + this.user_id).then(resposta => {
             
            if(resposta.data.status == "success"){
                this.user_info = resposta.data.data 
              }
           
          })
      }, 
      
    }
</script>
