<template>
    <div class="container ">
        <div class=" my-3 d-flex flex-row  justify-content-between">
            <h3 class="px-2 pt-2 card-title">Cadastre um novo frentista:</h3>
        </div>
      <div class="shadow p-3 my-4 rounded bg-white">
        <div v-if="this.dataResponse.data.status == 'success'"  class="alert alert-success" role="alert">
            {{this.dataResponse.data.data}}
        </div>
          <div v-if="this.dataResponse.data.status == 'erro'"  class="alert alert-danger" role="alert">
            {{this.dataResponse.data.data}}
        </div>
        
        <div class="row">
            <div class="col">
                <div class="col">
                    <div class="d-flex flex-column align-items-start form-group col">
                        <label for="inputName">Nome Completo:</label>
                        <input v-model="newAttendant.name" type="text" class="form-control-sm form-control" id="inputName" placeholder="Nome completo">
                    </div>
                </div>
                <div class="row">
                    <div class=" d-flex flex-column align-items-start form-group col"> 
                        <label for="inputPassword">Senha:</label>
                        <input v-model="newAttendant.password" type="password" class="form-control-sm form-control" id="inputPassword" placeholder="Senha">
                    </div>
                    <div class=" d-flex flex-column align-items-start form-group col"> 
                        <label  for="inputName">E-mail:</label>
                        <input v-model="newAttendant.email" type="email" class="form-control-sm form-control" id="inputEmail" placeholder="E-mail">
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row">
                    <div class="d-flex flex-column align-items-start form-group col">
                        <label  for="inputName">Telefone:</label>
                        <input v-mask="'(##) #####-####'" v-model="newAttendant.phone" type="email" class=" form-control-sm form-control" id="inputTelefone" placeholder="Telefone">
                    </div>
                    <div class=" d-flex flex-column align-items-start form-group col"> 
                        <label for="inputCPF">CPF:</label>
                        <input v-mask="'###.###.###-##'" v-model="newAttendant.cpf" type="text" class=" form-control-sm form-control" id="inputCPF" placeholder="CPF">
                    </div>
                </div>
                 <div class="col">
                    <div class="d-flex flex-column align-items-start form-group col">
                         <label for="inputAdress">Endereço:</label>
                        <input v-model="newAttendant.address"  type="text" class="form-control-sm form-control" id="inputAdress" placeholder="Endereço">
                    </div>
                </div>
            </div>
           </div>
           <div class="d-flex justify-content-end" >
            <button @click="register()"  class=" mt-3 btn btn-primary">Cadastrar</button>
            </div>
      </div>
        <div class=" my-5 d-flex flex-row  justify-content-between">
      
      </div>
    </div>
</template>
<script>
    import axios from 'axios';
    axios.defaults.headers.common = {'Authorization': "Bearer " + sessionStorage.getItem("token")}
    export default{
        name:'NewAttendantForm',

        data(){
        return {
            newAttendant:{
                station_id: this.$route.params.station_id,
           
                
            },

            dataResponse: {
                data:{
                    data:''
                }
            }

            
        }
        }, methods: {
            register() {
                var phone = this.newAttendant.phone.replace('(','');
                var phoneReplace = phone.replace(')','');
                var phoneReReplace = phoneReplace.replace(' ','');
                var phoneReReReplace = phoneReReplace.replace('-', '');
                var phoneReReReReplace = phoneReReReplace.replace(' ', '');
                this.newAttendant.phone = phoneReReReReplace 
                this.$http.post('station/registerNewAttendant', this.newAttendant).then(response => {

                this.dataResponse = response
                this.newAttendant = {}
                
                })
            }
        
        }
    }

</script>