<template>
    <div class="container mb-5">
      <div class="shadow p-3 my-5 rounded bg-white">
        <div v-if="this.dataResponse.data.status == 'success'"  class="alert alert-success" role="alert">
            {{this.dataResponse.data.data}}
        </div>
        <div v-if="this.dataResponse.data.status == 'erro'"  class="alert alert-danger" role="alert">
            {{this.dataResponse.data.data}}
        </div>
       
        <div class="mb-5 card d-flex flex-row">
            <div class="mt-2 card-body  col-md-6">
                <b>{{this.driver.name}}</b> irá dirigir:
               
            </div>
            <div class="mt-1 card-body col-md-6">
                <select v-model="selectedCar" class=" form-select form-select-sm " aria-label=".form-select-lg example">
                    <option  v-for="(car, index) in cars" :key="index">{{car.vehicle_id}} - Carro: {{car.model}} / Placa: {{car.plate}}</option>
                </select>
            </div>
              <button @click="associate()"  class="m-3 btn btn-primary">Associar</button>
        </div>
          <table class="table">
          <thead>
            <tr>
              <th scope="col">Motorista</th>
              <th scope="col">Veículo</th>
              <th scope="col">Placa</th>
              <th scope="col">Cor</th>
              <th scope="col">Excluir</th>
            </tr>
          </thead>
          <tbody  v-if="this.associates.data.status == 'success'">
            <tr v-for="(associate, index) in associates.data.data" :key="index" >
                <td><b> {{driver.name}}</b> dirige:</td>
                <td>{{associate.model}}</td>
                <td>{{associate.plate}}</td>
                <td>{{associate.color}}</td>
                <td><button type="button" @click="deleteAssociation(associate.id)" class="btn btn-sm btn-danger">Excluir</button></td>
            </tr>
          </tbody>
        </table>
        <span v-if="this.associates.data.status == 'erro'">
            {{this.associates.data.data}}
        </span>
      </div>
      <div class="my-5 d-flex flex-row-reverse">
        <br>
        <hr>
      </div>
    </div>
</template>
<script>
    import axios from 'axios';
    axios.defaults.headers.common = {'Authorization': "Bearer " + sessionStorage.getItem("token")}
    export default{
        name:'AssociateForm',

        data(){
            return {
                company_id: this.$route.params.company_id,
                driver:{
                    id: this.$route.params.driver_id,
                    name: this.$route.params.name
                },
                cars: {

                },
                drivercar: {
                    driver_id: this.$route.params.driver_id,
                    vehicle_id: ''

                },

                dataResponse: {
                    data:{
                        data:''
                    }
                },
                selectedCar: '',
                associates: {
                    data: {          
                    },
                    status: {

                    }
                },
                toDetele: {

                }

                
            }
        }, 
        mounted(){
        

            this.$http.get('company/getAllVehiclesBycompanyId/' + this.company_id).then(resposta => {
                this.cars = resposta.data.data;
                
            })

            this.$http.get('company/getAssociateVehicleByDriverId/' + this.driver.id).then(associacoes => {
                this.associates = associacoes
             
            })
        
        }, 
        methods: {
            associate() { 
                this.drivercar.vehicle_id = this.selectedCar.split(" ")[0];
                this.$http.post('/company/associateDriverVehicle', this.drivercar).then(response => {
            
                this.dataResponse = response

                console.log(this.cars)
                
                if(response.data.status == "success") {
                     this.$http.get('company/getAssociateVehicleByDriverId/' + this.driver.id).then(associacoes => {
                        this.associates = associacoes

                    })
                }
                
                })
            },
            deleteAssociation(id) {
                this.$http.post('company/deleteAssociationVehicle/'+id).then(response => {
                        this.dataResponse = response
                     this.$http.get('company/getAssociateVehicleByDriverId/' + this.driver.id).then(associacoes => {
                        this.associates = associacoes
                
                    })
                })
                
            }

        
        }
    }

</script>