<template>

    <div class="text-sm container-xxl my-4">
        <div class="text-sm container-xxl my-4">
            <div class="row justify-content-around">
                <div class="card-bg-0 text-light card bg-warning col-2">
                    <div class="align-items-start d-flex flex-column">
                        <h6 class="text-card-yellow pt-2">Inicio:</h6>
                        <small  class="text-card-yellow" >{{contract[0].initial_date}}</small>
                        <h6 class="text-card-yellow pt-2">Final:</h6>
                        <small  class="text-card-yellow">{{contract[0].closed_date}}</small>
                    </div>
                </div>
                <div class="card-bg-1 text-light card  bg-primary col-2">
                    <div class="align-items-start d-flex flex-column pt-2 pb-4">
                        <p >Total contratado:</p>
                        <h2 v-if="contract[0].amount_type == 1">  <money type="hidden" v-model="contract[0].contracted_amount" v-bind="money"></money> {{contract[0].contracted_amount}} L</h2>
                          <h2 v-if="contract[0].amount_type == 0">  <money type="hidden" v-model="contract[0].contracted_amount" v-bind="money"></money>R$ {{contract[0].contracted_amount}}</h2>
                    </div>
                </div>
                <div class="card-bg-2 text-light card bg-danger col-2">
                  <div class="align-items-start d-flex flex-column pt-2 pb-4">
                        <p>Total consumido:</p>
                        <h2 v-if="contract[0].amount_type == 1"><money type="hidden" v-model="contract[0].total" v-bind="money"></money> {{contract[0].total}} L</h2>
                        <h2 v-if="contract[0].amount_type == 0"><money type="hidden" v-model="contract[0].total" v-bind="money"></money> {{contract[0].total}} L</h2>
                    </div>
                </div>
                <div class="card-bg-3 text-light card bg-info col-2">
                  <div class="align-items-start d-flex flex-column pt-2 pb-4">
                        <p>Saldo restante:</p>
                        <h2 v-if="contract[0].amount_type == 1"><money type="hidden" v-model="contract[0].balance" v-bind="money"></money> {{contract[0].balance}} L</h2>
                        <h2 v-if="contract[0].amount_type == 0"><money type="hidden" v-model="contract[0].balance" v-bind="money"></money>R$ {{contract[0].balance}} </h2>
                    </div>
                </div>
                <div class="card-bg-4 text-light card  bg-success col-2">
                  <div class="align-items-start d-flex flex-column pt-2 pb-4">
                        <p>Qd. de abastecimentos:</p>
                        <h2>{{totalItems}}</h2>
                    </div>
                </div>
            </div>
        </div>
      <div class="table-responsive shadow rounded bg-white">
        <div class="border py-3 d-flex flex-row  justify-content-between">
          <h3 class="px-3 card-title">Histórico</h3>
          <vue-excel-xlsx class="mx-3 btn btn-sm btn-success"
          
            :data="historicsFiltered"
            :columns="columns"
            :file-name="'filename'"
            :file-type="'xlsx'"
            :sheet-name="'sheetname'"
            >
            Exportar
          </vue-excel-xlsx>
        </div>
          <b-skeleton-wrapper :loading="loading">
             <template #loading>
              <b-card  class="zeroPadding" style="border: none !important">
                <b-skeleton-table
                  :rows="5"
                  :columns="7"
                  :table-props="{ bordered: true, striped: true }"
                ></b-skeleton-table>
              </b-card>
            </template>
            <b-card clas="mx-3" style="border: none !important" >
               <div v-if="this.error !== ''"  class="alert alert-danger" role="alert">
                {{this.error}}
              </div>
              <table ref="exportable_table" v-if="this.error == ''"  class="table table-hover table-sm table-striped table-head-fixed text-nowrap">
                  <thead>
                      <tr class="table-format">  
                          <th v-for="(column, index) in columns" :key="index" scope="col">{{column.label}}</th>
                      </tr>
                      <tr class="align-middle">
                          <th >
                            <b-form-datepicker v-model="startDate" :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }" style="min-width: 150px" placeholder="De" id="datepicker-start" size="sm" locale="br" class=" tablefont mb-2"></b-form-datepicker>
                            <b-form-datepicker v-model="endDate" :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }" placeholder="Até" id="datepicker-end" size="sm" locale="br" class=" tablefont mb-2"></b-form-datepicker>
                          </th>
                          <th>
                            <select v-model="searchByFuel"  placeholder="Combustível" class=" tablefont form-select form-select-sm" >
                              <option selected value="">Todos</option>
                              <option value="alcool">Alcool</option>
                              <option value="gasolina">Gasolina</option>
                              <option  value="gasolina aditivada">Gasolina aditivada</option>
                              <option  value="diesel">Diesel</option>
                              <option  value="diesel s10">Diesel S10</option>

                            </select>
                          </th>
                          <th><input class="form-control tablefont form-control-sm" type="text" v-model="searchByAmount" placeholder="Valor abastecido"></th>
                          <th><input class="form-control tablefont form-control-sm" type="text" v-model="searchByAttendant" placeholder="Nome do Frentista"></th>
                          <th><input class="form-control tablefont form-control-sm" type="text" v-model="searchByCompany" placeholder="Nome da Empresa"></th>
                          <th><input class="form-control tablefont form-control-sm" type="text" v-model="searchByDriver" placeholder="Nome do Motorista"></th>
                          <th><input class="form-control tablefont form-control-sm" type="text" v-model="searchByPlate" placeholder="Placa"></th>
                          <th><input class="form-control tablefont form-control-sm" type="text" v-model="searchByModel" placeholder="Veículo"></th>
                          <th><input class="form-control tablefont form-control-sm" type="text" v-model="searchByColor" placeholder="Cor"></th>
                          <th>
                            <button title="Limpar filtro" @click="limparFiltro()" v-b-tooltip.hover class=" tablefont btn btn-primary btn-sm">Limpar</button>
                          </th>
              
                      </tr>
                    </thead>
                    <tbody >
                        <tr v-for="(historic, index) in historicsFiltered" :key="index" class="tablecontentfont table-format" >
                              <th scope="row">{{historic.datetime}}</th>
                              <td class=" px-2">{{historic.fuel}}</td>
                              <td><span v-if="contract[0].amount_type == 0">R$</span> {{historic.amount}}<span v-if="contract[0].amount_type == 1"> L</span></td>
                              <td>{{historic.attendant_name}}</td>
                              <td>{{historic.company_name}}</td>
                              <td>{{historic.driver_name}}</td>
                              <td>{{historic.plate}}</td>
                              <td>{{historic.model}}</td>
                              <td>{{historic.color}}</td>
                              <td></td>
                        </tr>
                        <tr class="table-format">
                          <th style="font-size: 1.2em" scope="row">Total: </th>
                          <td></td>
                          <td style="font-size: 1.2em"><span v-if="contract[0].amount_type == 0">R$</span> {{showSumTotal}}<span v-if="contract[0].amount_type == 1"> L</span></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                    </tbody>
                    </table>
                    
            </b-card>
        </b-skeleton-wrapper>
      </div>
      
    </div>
</template>
<style scoped>
.text-card-yellow {
  color: #715500;
}
.zeroPadding div {
  padding: 0 !important;
}
.table-format {
  text-align: left; font-size: 0.9em
}

.card-bg-0 {
  background-image: url('../assets/calendar.png') ;
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: 110% 300%;
  
}
.card-bg-0:hover {
  background-size: 51%;
  transition: 0.3s;
  
}


.card-bg-1 {
  background-image: url('../assets/handshake.png') ;
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: 110% 300%;
  
}
.card-bg-1:hover {
  background-size: 51%;
  transition: 0.3s;
  
}

.card-bg-2 {
  background-image: url('../assets/fuel.png') ;
  background-repeat: no-repeat;
  background-size: 40%;
  background-position: 100% 130%;
  
}
.card-bg-2:hover {
  background-size: 42%;
  transition: 0.3s;
  
}

.card-bg-3 {
  background-image: url('../assets/dollar.png') ;
  background-repeat: no-repeat;
  background-size: 45%;
  background-position: 110% 100%;
  
}


.card-bg-3:hover {
  background-size: 46%;
  transition: 0.3s;
  
}

.card-bg-4 {
  background-image: url('../assets/list.png') ;
  background-repeat: no-repeat;
  background-size: 40%;
  background-position: 110% 100%;
  
}
.card-bg-4:hover {
  background-size: 41%;
  transition: 0.3s;
  
}

</style>
<script>
import axios from 'axios';
axios.defaults.headers.common = {'Authorization': "Bearer " + sessionStorage.getItem("token")}

export default{
    name:'StationHistoric',
    data(){
      return {
        totalItems: 0,
        money: {
          decimal: ',',
          thousands: '.',
          prefix: ' ',
          suffix: '',
          precision: 2,
          masked: true,
        },
        total:0 ,
         columns : [
                    {
                        label: "Data e Hora",
                        field: "datetime",
                    },
                    {
                        label: "Combustivel",
                        field: "fuel",
                    },
                    {
                        label: "Valor",
                        field: "amount",
                        dataFormat: this.priceFormat
                    },
                    {
                        label: "Frentista",
                        field: "attendant_name",
                    },
                    {
                        label: "Empresa",
                        field: "company_name",
                    }, 
                    {
                        label: "Motorista",
                        field: "driver_name",
                    },
                    {
                        label: "Placa",
                        field: "plate",
                    },
                    {
                        label: "Veículo",
                        field: "model",
                    },
                    {
                        label: "Cor",
                        field: "color",
                    },
                    {
                      label: "Ações",
                       field: "",
                      
                    }
                      
                ],
                
        loading: false,
        loadingTime: 0,
        maxLoadingTime: 2,
        idCompany: this.$route.params.company_id,
        historics: [],
        error: '',
        searchByPlate:'',
        searchByColor:'',
        searchByModel:'',
        searchByDriver:'',
        searchByAmount: '',
        searchByFuel:'',
        searchByAttendant:'',
        searchByCompany:'',
        selected: null,
        startDate: null,
        endDate: null,
        amounts: [],
        idContract: this.$route.params.contract_id,
        contract: [
          {
            total: 0
          }
        ]
   
      }
    },
     watch: {
      loading(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.clearLoadingTimeInterval()

          if (newValue) {
            this.$_loadingTimeInterval = setInterval(() => {
              this.loadingTime++
            }, 1000)
          }
        }
      },
      loadingTime(newValue, oldValue) {
        if (newValue !== oldValue) {
          if (newValue === this.maxLoadingTime) {
            this.loading = false
          }
        }
      },
      
      
    },
    created() {
      this.$_loadingTimeInterval = null
  
    },
    methods: {
      sumTotal() {
          this.amounts = []
             let i = 0;
              for (i = 0; i < this.historicsFiltered.length; i++) {
                this.amounts.push(this.historicsFiltered[i].amount)
               
              } 
                this.total = this.amounts.reduce((a,b) => a + b)
             
               return this.total
             
      },
      priceFormat(value){
                return 'R$ ' + value;
      },
      localizeDate(date) {
       
        if (!date || !date.includes('-')) return date

        const [yyyy, mm, dd] = date.split('-')
        return new Date(`${mm}/${dd}/${yyyy}`)
      },
      dateReplace(date) {
       let data = date.split('/');
       let newdata = data[1] + '/' + data[0] + '/' + data[2];
       return newdata;
      },
      limparFiltro() {
        this.searchByPlate='',
        this.searchByColor='',
        this.searchByModel='',
        this.searchByDriver='',
        this.searchByAmount= '',
        this.searchByFuel='',
        this.searchByAttendant='',
        this.searchByCompany='',
        this.selected = null,
        this.startDate= null,
        this.endDate= null
        
      },
       clearLoadingTimeInterval() {
        clearInterval(this.$_loadingTimeInterval)
        this.$_loadingTimeInterval = null
      },
      startLoading() {
        this.loading = true
        this.loadingTime = 0
      }
    },
   computed: {
    historicsFiltered() {
        
      let startDate = this.localizeDate(this.startDate);
      let endDate = this.localizeDate(this.endDate);
      
            
        
        var valores = [];
      
          
        valores = this.historics.filter((historic) => {

          const historicDate = historic.datetime.substr(0, 10)
         
          const itemDate = new Date(this.dateReplace(historicDate))
                    
         
          if (this.searchByCompany || this.searchByAttendant || this.searchByFuel || this.searchByPlate || this.searchByDriver || this.searchByColor || this.searchByModel || this.searchByDriver ) {
           
            if(startDate && endDate) {
              return (
                historic.plate.toLowerCase().indexOf(this.searchByPlate.toLowerCase()) > -1 &&
                historic.driver_name.toLowerCase().indexOf(this.searchByDriver.toLowerCase()) > -1 &&
                historic.color.toLowerCase().indexOf(this.searchByColor.toLowerCase()) > -1 &&
                historic.model.toLowerCase().indexOf(this.searchByModel.toLowerCase()) > -1 &&
                historic.driver_name.toLowerCase().indexOf(this.searchByDriver.toLowerCase()) > -1 &&
                historic.fuel.toLowerCase().indexOf(this.searchByFuel.toLowerCase()) > -1 &&
                historic.attendant_name.toLowerCase().indexOf(this.searchByAttendant.toLowerCase()) > -1 &&
                historic.company_name.toLowerCase().indexOf(this.searchByCompany.toLowerCase()) > -1 &&
                startDate <= itemDate && itemDate <= endDate
                
                
              );
            }
             if(startDate && !endDate) {
              return (
                historic.plate.toLowerCase().indexOf(this.searchByPlate.toLowerCase()) > -1 &&
                historic.driver_name.toLowerCase().indexOf(this.searchByDriver.toLowerCase()) > -1 &&
                historic.color.toLowerCase().indexOf(this.searchByColor.toLowerCase()) > -1 &&
                historic.model.toLowerCase().indexOf(this.searchByModel.toLowerCase()) > -1 &&
                historic.driver_name.toLowerCase().indexOf(this.searchByDriver.toLowerCase()) > -1 &&
                historic.fuel.toLowerCase().indexOf(this.searchByFuel.toLowerCase()) > -1 &&
                historic.attendant_name.toLowerCase().indexOf(this.searchByAttendant.toLowerCase()) > -1 &&
                historic.company_name.toLowerCase().indexOf(this.searchByCompany.toLowerCase()) > -1 &&
                startDate <= itemDate
                
              );
            }
             if(!startDate && endDate) {
              return (
                historic.plate.toLowerCase().indexOf(this.searchByPlate.toLowerCase()) > -1 &&
                historic.driver_name.toLowerCase().indexOf(this.searchByDriver.toLowerCase()) > -1 &&
                historic.color.toLowerCase().indexOf(this.searchByColor.toLowerCase()) > -1 &&
                historic.model.toLowerCase().indexOf(this.searchByModel.toLowerCase()) > -1 &&
                historic.driver_name.toLowerCase().indexOf(this.searchByDriver.toLowerCase()) > -1 &&
                historic.fuel.toLowerCase().indexOf(this.searchByFuel.toLowerCase()) > -1 &&
                historic.attendant_name.toLowerCase().indexOf(this.searchByAttendant.toLowerCase()) > -1 &&
                historic.company_name.toLowerCase().indexOf(this.searchByCompany.toLowerCase()) > -1 &&
                itemDate <= endDate
                
              );
            }

             return (
              historic.plate.toLowerCase().indexOf(this.searchByPlate.toLowerCase()) > -1 &&
              historic.driver_name.toLowerCase().indexOf(this.searchByDriver.toLowerCase()) > -1 &&
              historic.color.toLowerCase().indexOf(this.searchByColor.toLowerCase()) > -1 &&
              historic.model.toLowerCase().indexOf(this.searchByModel.toLowerCase()) > -1 &&
              historic.driver_name.toLowerCase().indexOf(this.searchByDriver.toLowerCase()) > -1 &&
              historic.fuel.toLowerCase().indexOf(this.searchByFuel.toLowerCase()) > -1 &&
              historic.attendant_name.toLowerCase().indexOf(this.searchByAttendant.toLowerCase()) > -1 &&
              historic.company_name.toLowerCase().indexOf(this.searchByCompany.toLowerCase()) > -1 
             )
          }
          
          if(startDate && endDate) {
            
              return (
                historic.plate.toLowerCase().indexOf(this.searchByPlate.toLowerCase()) > -1 &&
                historic.driver_name.toLowerCase().indexOf(this.searchByDriver.toLowerCase()) > -1 &&
                historic.color.toLowerCase().indexOf(this.searchByColor.toLowerCase()) > -1 &&
                historic.model.toLowerCase().indexOf(this.searchByModel.toLowerCase()) > -1 &&
                historic.driver_name.toLowerCase().indexOf(this.searchByDriver.toLowerCase()) > -1 &&
                historic.fuel.toLowerCase().indexOf(this.searchByFuel.toLowerCase()) > -1 &&
                historic.attendant_name.toLowerCase().indexOf(this.searchByAttendant.toLowerCase()) > -1 &&
                historic.company_name.toLowerCase().indexOf(this.searchByCompany.toLowerCase()) > -1 &&
                startDate <= itemDate && itemDate <= endDate
                
              );
          }

          if(startDate && !endDate) {
              
              return (
                historic.plate.toLowerCase().indexOf(this.searchByPlate.toLowerCase()) > -1 &&
                historic.driver_name.toLowerCase().indexOf(this.searchByDriver.toLowerCase()) > -1 &&
                historic.color.toLowerCase().indexOf(this.searchByColor.toLowerCase()) > -1 &&
                historic.model.toLowerCase().indexOf(this.searchByModel.toLowerCase()) > -1 &&
                historic.driver_name.toLowerCase().indexOf(this.searchByDriver.toLowerCase()) > -1 &&
                historic.fuel.toLowerCase().indexOf(this.searchByFuel.toLowerCase()) > -1 &&
                historic.attendant_name.toLowerCase().indexOf(this.searchByAttendant.toLowerCase()) > -1 &&
                historic.company_name.toLowerCase().indexOf(this.searchByCompany.toLowerCase()) > -1 &&
                startDate <= itemDate
                
              );
          }

          if(!startDate && endDate) {
              return (
                historic.plate.toLowerCase().indexOf(this.searchByPlate.toLowerCase()) > -1 &&
                historic.driver_name.toLowerCase().indexOf(this.searchByDriver.toLowerCase()) > -1 &&
                historic.color.toLowerCase().indexOf(this.searchByColor.toLowerCase()) > -1 &&
                historic.model.toLowerCase().indexOf(this.searchByModel.toLowerCase()) > -1 &&
                historic.driver_name.toLowerCase().indexOf(this.searchByDriver.toLowerCase()) > -1 &&
                historic.fuel.toLowerCase().indexOf(this.searchByFuel.toLowerCase()) > -1 &&
                historic.attendant_name.toLowerCase().indexOf(this.searchByAttendant.toLowerCase()) > -1 &&
                historic.company_name.toLowerCase().indexOf(this.searchByCompany.toLowerCase()) > -1 &&
                itemDate <= endDate
                
              );
          }

          return (
            
            historic.attendant_name.toLowerCase().indexOf(this.searchByCompany.toLowerCase()) > -1 ||
            historic.driver_name.toLowerCase().indexOf(this.searchByCompany.toLowerCase()) > -1 ||
            historic.plate.toLowerCase().indexOf(this.searchByCompany.toLowerCase()) > -1 ||
            historic.color.toLowerCase().indexOf(this.searchByCompany.toLowerCase()) > -1 ||
            historic.model.toLowerCase().indexOf(this.searchByCompany.toLowerCase()) > -1 ||
            historic.amount.toLowerCase().indexOf(this.searchByCompany.toLowerCase()) > -1 ||
            historic.company_name.toLowerCase().indexOf(this.searchByCompany.toLowerCase()) > -1 ||
            historic.fuel.toLowerCase().indexOf(this.searchByCompany.toLowerCase()) > -1

          );
        });
      
        return valores
      
    },
    showSumTotal(){
      let soma = '';
      soma = this.sumTotal().toFixed(2);
      return soma;
    },
   
  
      
    },

    mounted(){
       this.startLoading(),
        this.$http.get('station/getHistoricStationByContractId/' + this.idContract).then(resposta => {
      
           if(resposta.data.status == "success"){
             this.historics = resposta.data.data;
             this.totalItems = this.historics.length
            }
          if(resposta.data.status == "erro") {
            this.error = resposta.data.data;
            }
        
        }),
         this.$http.get('station/getContractById/' + this.idContract).then(resposta => {
          
          if(resposta.data.status == "success"){
            this.contract = resposta.data.data;
            console.log(this.contract)
            this.contract[0].total =  this.contract[0].contracted_amount - this.contract[0].balance;
            
            
          }
          if(resposta.data.status == "erro"){
            this.error = resposta.data.data;
          }

        
        })

      
    }, 
     
  }
</script>