<template>
    <div class="container ">
        <div class=" my-3 d-flex flex-row  justify-content-between">
            <h3 class="px-2 pt-2 card-title">Edição de frentista:</h3>
        </div>
      <div class="shadow p-3 my-4 rounded bg-white">
        <div v-if="this.dataResponse.data.status == 'success'"  class="alert alert-success" role="alert">
            {{this.dataResponse.data.data}}
        </div>
          <div v-if="this.dataResponse.data.status == 'erro'"  class="alert alert-danger" role="alert">
            {{this.dataResponse.data.data}}
        </div>
        
        <!-- <form class="p-3"> -->
            <div class="p-2 d-flex">
                <div class="d-flex flex-column align-items-start form-group m-1 col-md-12">
                    <label for="inputName">Nome Completo:</label>
                    <input v-model="Attendant.name" type="email" class="form-control" id="inputName" placeholder="Nome completo">
                </div>
                
            </div>
            <div class="p-2 d-flex">
                <div class="d-flex flex-column align-items-start form-group m-1 col-md-6">
                    <label for="inputTelefone">Telefone:</label>
                    <input v-mask="'(##) #####-####'" v-model="Attendant.phone" type="email" class="form-control" id="inputTelefone" placeholder="Telefone">
                </div>
                <div class="d-flex flex-column align-items-start form-group m-1 col-md-6">
                    <label for="inputCPF">CPF:</label>
                    <input v-mask="'###.###.###-##'" v-model="Attendant.cpf" type="text" class="form-control" id="inputCPF" placeholder="CPF">
                </div>       
            </div>
            <div class="p-2 d-flex">
                <div class="d-flex flex-column align-items-start form-group m-1 col-md-12">
                    <label for="inputEmail">E-mail:</label>
                    <input v-model="Attendant.email" type="email" class="form-control" id="inputEmail" placeholder="E-mail">
                </div>                   
            </div>
             <div class="p-2 d-flex">
                <div class="d-flex flex-column align-items-start form-group m-1 col-md-6">
                    <label for="inputPassword">Senha:</label>
                    <input v-model="Attendant.password" type="password" class="form-control" id="inputPassword" placeholder="Senha">
                </div>
                   
            </div>
            <div class="p-2 d-flex">
                <div class="d-flex flex-column align-items-start form-group m-1 col-md-12">
                    <label for="inputAdress">Endereço:</label>
                    <input v-model="Attendant.address"  type="text" class="form-control" id="inputAdress" placeholder="Endereço">
                </div>
                <!-- <div class="d-flex flex-column align-items-start form-group m-1 col-md-2">
                    <label for="inputPassword4">Número:</label>
                    <input v-model="number" type="password" class="form-control" id="inputPassword4" placeholder="Password">
                </div>
                <div class="d-flex flex-column align-items-start form-group m-1 col-md-5">
                    <label for="inputPassword4">Bairro:</label>
                    <input v-model="bairro" type="password" class="form-control" id="inputPassword4" placeholder="Password">
                </div> -->
            </div>
            <div class="d-flex justify-content-end" >
                <button @click="register()"  class="m-3 btn btn-primary">Atualizar</button>
            </div>
        <!-- </form> -->
      </div>
        <div class=" my-5 d-flex flex-row  justify-content-between">
      
      </div>
    </div>
</template>
<script>
    import axios from 'axios';
    axios.defaults.headers.common = {'Authorization': "Bearer " + sessionStorage.getItem("token")}
    export default{
        name:'EditAttendantForm',

        data(){
        return {
            Attendant: {

            },
           attendantId: this.$route.params.attendant_id,

            dataResponse: {
                data:{
                    data:''
                }
            }

            
        }
        }, methods: {
            register() { 
                this.$http.post('station/editAttendantById', this.Attendant).then(response => {

                this.dataResponse = response
                this.Attendant = {}
                
                })
            }
        
        },
        
        mounted(){
            
            this.$http.get('station/getAttendantById/' + this.attendantId ).then(resposta => {
                
            this.Attendant = resposta.data.data[0]
        
            })
        
        },
    }

</script>