<template>
    <div  class="text-sm container-xxl my-4">
      <div class="table-responsive shadow rounded bg-white">
        <div class="border py-3 d-flex flex-row  justify-content-between">
          <h3 class="px-3 card-title">Motoristas</h3>
          <button type="button" @click="routerPush()" class="m-2 btn btn-sm btn-success">Novo motorista</button>
      </div>
       <div v-if="this.dataResponse.data.status == 'success'"  class="alert alert-success" role="alert">
            {{this.dataResponse.data.data}}
        </div>
        <div v-if="this.dataResponse.data.status == 'erro'"  class="alert alert-danger" role="alert">
            {{this.dataResponse.data.data}}
        </div>
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card style="border: none !important">
              <b-skeleton-table
                :rows="5"
                :columns="8"
                :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>
            </b-card>
          </template>

          <b-card style="border: none !important" >
            <div v-if="this.error !== ''"  class="alert alert-danger" role="alert">
              {{this.error}}
            </div>
            <table v-if="this.error == ''"   class="table-sm table-hover table-bordered table table-striped">
              <thead>
                <tr>
                 <th scope="col">#</th>
                  <th scope="col">Nome</th>
                  <th scope="col">Telefone</th>
                  <th scope="col">Email</th>
                  <th scope="col">CPF</th>
                  <th scope="col">Endereço</th>
                  <th scope="col">Status</th>
                  <th scope="col"> Editar </th>
                  <th scope="col"> Excluir </th>
                  <th scope="col">Veículos</th>
                  
                </tr>
              </thead>
              <tbody>
                
                <tr class="tablefont" v-for="(driver, index) in drivers" :key="index">
                  <th scope="row">{{driver.id}}</th>
                <td>{{driver.name}}</td>
                <td>{{driver.phone}}</td>
                <td>{{driver.email}}</td>
                <td>{{driver.cpf}}</td>
                <td>{{driver.address}}</td>
                 <td v-if="driver.status == 1">Ativo</td>
                <td v-if="driver.status == 0">Inativo</td>
                <td><button @click="routerPushEdit(driver.id)" class="btn btn-warning btn-sm">Editar</button></td>
                <td><button @click="deleteDriver(driver.id)" class="btn btn-danger btn-sm">Excluir</button></td>
                
                <td><button @click="routerPushAssociateVehicle(driver.id, driver.name)" class="btn btn-success btn-sm">Adicionar</button></td>
                </tr>
                
              </tbody>
            </table>
          </b-card>
    </b-skeleton-wrapper>
  </div>
    </div>
</template>
<script>
import axios from 'axios';
axios.defaults.headers.common = {'Authorization': "Bearer " + sessionStorage.getItem("token")}

export default{
    name:'ContentListDriver',

    data(){
      return {
        loading: false,
        loadingTime: 0,
        maxLoadingTime: 2,
        idUser: this.$route.params.user_id,
        idCompany: this.$route.params.company_id,
        drivers: {},
        error: '',
        dataResponse: {
          data:{
            data:''
              }
        },
      }
    },
    watch: {
      loading(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.clearLoadingTimeInterval()

          if (newValue) {
            this.$_loadingTimeInterval = setInterval(() => {
              this.loadingTime++
            }, 1000)
          }
        }
      },
      loadingTime(newValue, oldValue) {
        if (newValue !== oldValue) {
          if (newValue === this.maxLoadingTime) {
            this.loading = false
          }
        }
      }
    },
    created() {
      this.$_loadingTimeInterval = null
    },

    mounted(){
        this.startLoading(),
        this.$http.get('company/getAllDriversBycompanyId/' + this.idCompany ).then(resposta => {
         
         if(resposta.data.status == "success"){
            return this.drivers = resposta.data.data;
         }
         if(resposta.data.status == "erro") {
           this.error = resposta.data.data;
         }
         
            
        })
      
    },

    methods: {
      clearLoadingTimeInterval() {
        clearInterval(this.$_loadingTimeInterval)
        this.$_loadingTimeInterval = null
      },
      startLoading() {
        this.loading = true
        this.loadingTime = 0
      },
      routerPush() {
       
        this.$router.push('/cadastro_motorista/' + this.idCompany + '/' + this.idUser)
      },
      routerPushAssociateVehicle(id, name) {
       
         this.$router.push('/associa_veiculo/' + id + '/' + name + '/' + this.idCompany + '/' + this.idUser)
      },
      deleteDriver(id) {
        this.$http.get('company/deleteDriverById/' + id).then(resposta => {
        this.dataResponse = resposta
        console.log(resposta)
            this.$http.get('company/getAllDriversBycompanyId/' + this.idCompany ).then(resposta => {
         
              if(resposta.data.status == "success"){
                  return this.drivers = resposta.data.data;
              }
              if(resposta.data.status == "erro") {
                this.error = resposta.data.data;
              }
              
                  
              })
        })
      },
       routerPushEdit(driverId) {
        this.$router.push('/editdriver/' + this.idCompany + '/' + driverId)
      },
    }
}

</script>