<template>
  <div class="height-div table-responsive shadow rounded bg-white m-3">
       <table class="m-3 table table-hover table-sm table-striped table-head-fixed text-nowrap">
            <thead>
                <tr class="align-middle">
                    <th >
                      Id
                    </th>
                     <th >
                        Razão Social
                    </th>
                     <th >
                      Endereço
                    </th>
                     <th >
                      CNPJ
                    </th>
                     <th >
                      Telefone
                    </th>
                     <th>
                        Cidade
                    </th>
                    <th>
                        UF
                    </th>
                     <th >
                      CEP
                    </th>
                     <th >
                      E-mail
                    </th>
                     <th >
                      Data e Hora
                    </th>
                    <th >
                      Status
                    </th>
                </tr>
            </thead>
            <tbody >
                <tr v-for="(empresa, index) in empresas" :key="index" class="tablecontentfont table-format" >
                    <td>
                        {{empresa['id']}}
                    </td>
                    <td >
                        {{empresa['name']}}
                    </td>  
                    <td>
                        {{empresa['address']}}
                    </td>  
                    <td>
                        {{empresa['cnpj']}}
                    </td>  
                    <td>
                        {{empresa['phone']}}
                    </td>  
                    <td>
                        {{empresa['uf']}}
                    </td>  
                    <td>
                        {{empresa['city']}}
                    </td>  
                    <td>
                        {{empresa['cep']}}
                    </td>  
                    <td>
                        {{empresa['email']}}
                    </td> 
                      <td>
                        {{empresa['datetime']}}
                    </td> 
                      <td>
                        {{empresa['status']}}
                    </td> 
                    
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: 'EmpresaTabela',
    data() {
        return {
            empresas: {

            }
        }
    },
    mounted(){
       this.$http.get('company/getAllCompanies').then(resposta => {
         
           if(resposta.data.status == "success"){
                this.empresas = resposta.data.data
            }
        })
    }

}
</script>

<style>

</style>