<template>
 <div class="text-sm container-xxl my-4">
      <div class="table-responsive shadow rounded bg-white">
        <div class="border py-3 d-flex flex-row  justify-content-between">
          <h3 class="px-3 card-title">Contratos</h3>
          <button @click="routerPushContract(idStation)"  class="mx-3 btn btn-sm btn-success">
            Novo contrato
          </button>
        </div>
          <b-skeleton-wrapper :loading="loading">
             <template #loading>
              <b-card  style="border: none !important">
                <b-skeleton-table
                  :rows="5"
                  :columns="7"
                  :table-props="{ bordered: true, striped: true }"
                ></b-skeleton-table>
              </b-card>
            </template>
            <b-card style="min-height: 330px !important; overflow:auto; border: none !important" >
               <div v-if="this.error !== ''"  class="alert alert-danger" role="alert">
                {{this.error}}
              </div>
              <table  v-if="this.error == ''"  class="table-sm table-hover table-bordered table table-striped">
                  <thead>
                        <tr>
                        <th scope="col">Cod.</th>
                        <th scope="col">Empresa</th>
                        <th scope="col">Data Inicial</th>
                        <th scope="col">Data Final</th>
                        <th scope="col">Status</th>
                        <th scope="col">Saldo contratado</th>
                        <th scope="col">Saldo restante</th>
                        <th scope="col">Observações</th>
                        <th scope="col">Ação</th>
                      </tr>
                      <tr class="align-middle">
                       
                          <th>
                          <input class="form-control tablefont form-control-sm" type="text"  v-model="searchByCod" placeholder="Código">
                          </th>
                          <th><input class="form-control tablefont form-control-sm" type="text" v-model="searchByCompany" placeholder="Empresa"></th>
                           <th>
                             <b-form-datepicker style="width: 150px" v-model="startDate" :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }" placeholder="De" id="datepicker-start" size="sm" locale="br" class=" tablefont mb-2"></b-form-datepicker>
                          </th>
                           <th >
                            <b-form-datepicker  style="width: 150px" v-model="endDate" :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }" placeholder="Até" id="datepicker-end" size="sm" locale="br" class=" tablefont mb-2"></b-form-datepicker>
                          </th>
                          <th>
                            <select v-model="searchByStatus"  placeholder="Combustível" class=" tablefont form-select form-select-sm" >
                              <option selected value="">Selecione</option>
                              <option value="0">Ativo</option>
                              <option value="1">Expirado</option>
                              <option value="2">Esgotado</option>
                            </select>
                          </th>
                          <th><input class="form-control tablefont form-control-sm" type="text" v-model="searchByContractedBalance" placeholder="Saldo Contratado"></th>
                          <th><input class="form-control tablefont form-control-sm" type="text" v-model="searchByCurrentBalance" placeholder="Saldo Restante"></th>
                          <th></th>
                          <th>
                            <button title="Limpar filtro" @click="limparFiltro()" v-b-tooltip.hover class="mx-2 tablefont btn btn-primary btn-sm">Limpar</button>
                          </th>
              
                      </tr>
                    
                     
                    </thead>
                      <tbody>
            
                        <tr v-for="(contract, index) in contractsFiltered" :key="index">
                          <th >PAG-{{contract.contract_id}}</th>
                          <th >{{contract.company_name}}</th>
                          <td >{{contract.initial_date}}</td>
                          <td>{{contract.closed_date}}</td>
                          <td v-if="contract.status == 2" class="d-flex justify-content-start left-red">Esgotado</td>
                          <td v-if="contract.status == 1" class="d-flex justify-content-start left-yellow">Expirado</td>
                          <td v-if="contract.status == 0" class="d-flex justify-content-start left-green">Ativo</td>
                          <td v-if="contract.amount_type == 0" >R$ {{contract.contracted_amount}}</td>
                          <td v-if="contract.amount_type == 1" >{{contract.contracted_amount}} L</td>
                          <td v-if="contract.amount_type == 0" >R$ {{contract.balance}}</td>
                          <td v-if="contract.amount_type == 1" >{{contract.balance}} L </td>
                          <td>{{contract.comments}}</td>
                          <td><a href="#" class="link-success" @click="routerPushHistoric(contract.company_id, contract.contract_id)" >Acessar</a></td>
                        </tr>
                        
                      </tbody>
                    </table>
                  
            </b-card>
        </b-skeleton-wrapper>

       
      </div>
    </div>
   
</template>
<style scoped>

.left-yellow {
  border-left: 5px solid rgb(255, 174, 0);

}
.left-red {
  border-left: 5px solid rgb(255, 38, 0);

}
.left-green{
   border-left: 5px solid rgb(14, 169, 14);
}
</style>
<script>
import axios from 'axios';
axios.defaults.headers.common = {'Authorization': "Bearer " + sessionStorage.getItem("token")}

export default{
    name:'StationContracts',
    data(){
      return {
        userId: this.$route.params.user_id,
        idStation: this.$route.params.station_id,
        contracts: [],
        error: '',
        loading: false,
        loadingTime: 0,
        maxLoadingTime: 2,
         money: {
          decimal: ',',
          thousands: '.',
          prefix: '',
          suffix: '',
          precision: 2,
          masked: true,
        },
        searchByCod:'',
        searchByCompany:'',
        searchByStatus:'',
        startDate:'',
        endDate:'',
        searchByContractedBalance: '',
        searchByCurrentBalance: ''
      }
      
    },
     watch: {
      loading(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.clearLoadingTimeInterval()

          if (newValue) {
            this.$_loadingTimeInterval = setInterval(() => {
              this.loadingTime++
            }, 1000)
          }
        }
      },
      loadingTime(newValue, oldValue) {
        if (newValue !== oldValue) {
          if (newValue === this.maxLoadingTime) {
            this.loading = false
          }
        }
      }
    },
     created() {
      this.$_loadingTimeInterval = null
    },

    mounted(){
         this.startLoading(),
        this.$http.get('station/getContractByStationId/' + this.idStation).then(resposta => {
          
          if(resposta.data.status == "success"){
            this.contracts = resposta.data.data;
          }
          if(resposta.data.status == "erro"){
            this.error = resposta.data.data;
          }  
        })
      
    },
     methods: {
       limparFiltro() {
        this.searchByCod = '',
        this.searchByCompany = '',
        this.searchByStatus = '',
        this.searchByContractedBalance =  '',
        this.searchByCurrentBalance =  ''
        this.selected = null,
        this.startDate = null,
        this.endDate = null
        
      },
      routerPushHistoric(company_id, contract_id) {
       
        this.$router.push('/station/historic/' + this.idStation + '/' + this.userId + '/' + company_id + '/' + contract_id)
      },
      routerPushContract(station_id) {
       
        this.$router.push('/generatecontract/contract/' + station_id + '/' + this.userId)
      },
      clearLoadingTimeInterval() {
        clearInterval(this.$_loadingTimeInterval)
        this.$_loadingTimeInterval = null
      },
      startLoading() {
        this.loading = true
        this.loadingTime = 0
      },
      dateReplace(date) {
       let data = date.split('/');
       let newdata = data[1] + '/' + data[0] + '/' + data[2];
       return newdata;
      },
        localizeDate(date) {
       
        if (!date || !date.includes('-')) return date

        const [yyyy, mm, dd] = date.split('-')
        return new Date(`${mm}/${dd}/${yyyy}`)
      },
    },
    computed: {
       contractsFiltered() {
      
        
      let startDate = this.localizeDate(this.startDate);
      let endDate = this.localizeDate(this.endDate);
      
                
            var valores = [];
          
              
            valores = this.contracts.filter((contract) => {
              
              contract.contract_id = contract.contract_id.toString()
              contract.status =  contract.status.toString();
              contract.contracted_amount = contract.contracted_amount.toString()
              contract.balance = contract.balance.toString()

              const contractDate = contract.initial_date
              const finalDate = contract.closed_date
              const itemDateClosed = new Date(this.dateReplace(finalDate))
              const itemDate = new Date(this.dateReplace(contractDate))
            
              
            
              if (this.searchByCod || this.searchByCompany  || this.searchByStatus || this.searchByContractedBalance || this.searchByCurrentBalance ) {
               
                if(startDate && endDate) {
                 
                  return (
                      contract.contract_id.indexOf(this.searchByCod) > -1 &&
                      contract.company_name.toLowerCase().indexOf(this.searchByCompany.toLowerCase()) > -1 &&
                      contract.status.indexOf(this.searchByStatus) > -1  &&
                      contract.contracted_amount.indexOf(this.searchByContractedBalance) > -1 &&
                      contract.balance.indexOf(this.searchByCurrentBalance) > -1 &&
                      startDate <= itemDate && itemDateClosed <= endDate
                    
                    
                  );
                }
                if(startDate && !endDate) {
                 
                  return(
                     contract.contract_id.indexOf(this.searchByCod) > -1 &&
                      contract.company_name.toLowerCase().indexOf(this.searchByCompany.toLowerCase()) > -1 &&
                      contract.status.indexOf(this.searchByStatus) > -1  &&
                      contract.contracted_amount.indexOf(this.searchByContractedBalance) > -1 &&
                      contract.balance.indexOf(this.searchByCurrentBalance) > -1 &&
                      startDate <= itemDate
                    )
                
                }
                if(!startDate && endDate) {
                  return (
                    contract.contract_id.indexOf(this.searchByCod) > -1 &&
                    contract.company_name.toLowerCase().indexOf(this.searchByCompany.toLowerCase()) > -1 &&
                    contract.status.indexOf(this.searchByStatus) > -1  &&
                    contract.contracted_amount.indexOf(this.searchByContractedBalance) > -1 &&
                    contract.balance.indexOf(this.searchByCurrentBalance) > -1 &&
                    itemDateClosed >= endDate
                    
                  );
                }

                return (
                  contract.contract_id.indexOf(this.searchByCod) > -1 &&
                    contract.company_name.toLowerCase().indexOf(this.searchByCompany.toLowerCase()) > -1 &&
                    contract.status.indexOf(this.searchByStatus) > -1  &&
                    contract.contracted_amount.indexOf(this.searchByContractedBalance) > -1 &&
                    contract.balance.indexOf(this.searchByCurrentBalance) > -1 
                )
              }
              
              if(startDate && endDate) {
                
                  return (
                    contract.contract_id.indexOf(this.searchByCod) > -1 &&
                    contract.company_name.toLowerCase().indexOf(this.searchByCompany.toLowerCase()) > -1 &&
                    contract.status.indexOf(this.searchByStatus) > -1  &&
                    contract.contracted_amount.indexOf(this.searchByContractedBalance) > -1 &&
                    contract.balance.indexOf(this.searchByCurrentBalance) > -1 &&
                    startDate <= itemDate && itemDateClosed <= endDate
                    
                  );
              }

              if(!startDate && endDate) {
                  return(
                      contract.contract_id.indexOf(this.searchByCod) > -1 &&
                      contract.company_name.toLowerCase().indexOf(this.searchByCompany.toLowerCase()) > -1 &&
                      contract.status.indexOf(this.searchByStatus) > -1  &&
                      contract.contracted_amount.indexOf(this.searchByContractedBalance) > -1 &&
                      contract.balance.indexOf(this.searchByCurrentBalance) > -1 &&
                      startDate >= itemDateClosed
                    )
                
              }
              if(startDate && !endDate) {
                  return(
                      contract.contract_id.indexOf(this.searchByCod) > -1 &&
                      contract.company_name.toLowerCase().indexOf(this.searchByCompany.toLowerCase()) > -1 &&
                      contract.status.indexOf(this.searchByStatus) > -1  &&
                      contract.contracted_amount.indexOf(this.searchByContractedBalance) > -1 &&
                      contract.balance.indexOf(this.searchByCurrentBalance) > -1 &&
                      startDate <= itemDate
                    )
                
              }
                 
              return (
                  contract.contract_id.indexOf(this.searchByCod) > -1 &&
                  contract.company_name.toLowerCase().indexOf(this.searchByCompany.toLowerCase()) > -1 &&
                  contract.status.indexOf(this.searchByStatus) > -1  &&
                  contract.contracted_amount.indexOf(this.searchByContractedBalance) > -1 &&
                  contract.balance.indexOf(this.searchByCurrentBalance) > -1 

              );

  
             });
          
             return valores
          
        },
    }
     
  }
</script>