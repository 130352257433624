<template>
  <footer class="bg-secondary text-center text-lg-start">

  <div class=" text-dark bg-footer fixarRodape text-center p-3">
        <img style="width: 4%" src="../assets/icone_footer.png" alt="">
    © 2022 Copyright:
    <a class="text-dark" href="painel.pagposto.ecommercenet.com.br/">PagPosto</a>
  </div>

</footer>
</template>
<style scoped>
.bg-footer {
  background: #e4e4e4;
}
  .fixarRodape {
          bottom: 0;
          position: fixed;
          width: 100%;
          max-height: 70px;
          text-align: center;
          z-index: 10;
      }
</style>
<script>
export default{
    name:'FooterApp'
}
</script>
