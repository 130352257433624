<template >
  <div class="height-div table-responsive shadow rounded bg-white m-3">
       <table class="m-3 table table-hover table-sm table-striped table-head-fixed text-nowrap">
            <thead>
                <tr class="align-middle">
                    <th >
                      Id
                    </th>
                     <th >
                        Modelo
                    </th>
                     <th >
                        Cor
                    </th>
                     <th >
                      Placa
                    </th>
                     <th >
                      Combustivel
                    </th>
                     <th>
                       Empresa
                    </th>
                    <th>
                       Imagem
                    </th>
                     <th >
                      Status
                    </th>
                     <th >
                      Datetime
                    </th>
                </tr>
            </thead>
            <tbody >
                <tr v-for="(veiculo, index) in veiculos" :key="index" class="tablecontentfont table-format" >
                    <td>
                        {{veiculo['id']}}
                    </td>
                    <td >
                        {{veiculo['model']}}
                    </td>  
                    <td>
                        {{veiculo['color']}}
                    </td>  
                    <td>
                        {{veiculo['plate']}}
                    </td>  
                    <td>
                        {{veiculo['fuel']}}
                    </td>  
                    <td>
                        {{veiculo['company']}}
                    </td>  
                    <td>
                        {{veiculo['image']}}
                    </td>  
                    <td>
                        {{veiculo['status']}}
                    </td> 
                    <td>
                        {{veiculo['datetime']}}
                    </td> 
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: 'VeiculoTabela',
    data() {
        return {
            veiculos: {

            }
        }
    },
    mounted(){
        this.$http.get('company/getAllVehicles').then(resposta => {
         
           if(resposta.data.status == "success"){
                this.veiculos = resposta.data.data
            }
        })
    }

}
</script>

<style>

</style>