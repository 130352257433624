<template>
    <div class="container ">
        <div class=" my-3 d-flex flex-row  justify-content-between">
            <h3 class="px-2 pt-2 card-title">Gere um novo contrato:</h3>
        </div>
      <div class="shadow p-3 my-4 rounded bg-white">
        <div v-if="this.dataResponse.data.status == 'success'"  class="alert alert-success" role="alert">
            {{this.dataResponse.data.data.data}}
        </div>
          <div v-if="this.dataResponse.data.status == 'erro'"  class="alert alert-danger" role="alert">
            {{this.dataResponse.data.data.data}}
        </div>
        <form class="g-3 needs-validation" @submit.prevent="">
            <div class="row">
                <div class="col">
                    <div class="pt-1 d-flex flex-column align-items-start form-group col-md-12">
                        <label for="inputEmail4">Selecione a empresa:</label>
                        <select v-model="key"  @change="fillCompanyInputs(key)" class="form-select form-select-sm" aria-label="Default select example">
                            <option selected>Nova empresa</option>
                            <option :value="company.id" v-for="(company, index) in companies.data" :key="index">{{company.name}}</option>
                        </select>
                    </div>
                    <div class="pt-2 d-flex flex-column align-items-start form-group col-md-12"> 
                            <label for="inputEmail4">Razão social:</label>
                            <input  disabled v-if="companyExists.nome" :value="companyExists.nome" type="text" class="form-control-sm form-control" id="inputEmail4" placeholder="Razão social">  
                            <input :required="true" v-else v-model="new_contract.companyRegister.name" type="text" class="form-control-sm form-control" id="inputEmail4" placeholder="Razão social">          
                    </div>
                    <div class="pt-2 d-flex flex-column align-items-start form-group col-md-12"> 
                            <label for="inputEmail4">CNPJ:</label>
                            <input disabled v-if="companyExists.cnpj" type="text" :value="companyExists.cnpj" class="form-control-sm form-control" id="inputEmail4" placeholder="CNPJ">     
                            <input :required="true" v-else v-model="new_contract.companyRegister.cnpj" v-mask="'##############'" type="text" class="form-control-sm form-control" id="inputEmail4" placeholder="CNPJ">       
                    </div>
                    <div class="row">
                            <div class="pt-2 d-flex flex-column align-items-start form-group col-7"> 
                                <label for="inputEmail4">E-mail:</label>
                                <input disabled v-if="companyExists.email" :value="companyExists.email" type="text" class="form-control-sm form-control" id="inputEmail4" placeholder="E-mail">      
                                <input :required="true" v-else v-model="new_contract.companyRegister.email" type="text" class="form-control-sm form-control" id="inputEmail4" placeholder="E-mail">      
                            </div>
                            <div class="pt-2 d-flex flex-column align-items-start form-group col-5"> 
                                <label for="inputEmail4">Telefone:</label>
                                <input disabled v-if="companyExists.telefone" :value="companyExists.telefone" v-mask="'(##) # ####-####'" type="text" class="form-control-sm form-control" id="inputEmail4" placeholder="Telefone">      
                                <input :required="true" v-else v-mask="'(##) # ####-####'" v-model="new_contract.companyRegister.phone" type="text" class="form-control-sm form-control" id="inputEmail4" placeholder="Telefone">      
                            </div>
                    </div>
                    <div class="row"> 
                            <div class="pt-2 d-flex flex-column align-items-start form-group col-md-4"> 
                                <label for="inputEmail4">CEP:</label>
                                <input disabled v-if="companyExists.cep" :value="companyExists.cep" type="text" class="form-control-sm form-control" id="inputEmail4" placeholder="CEP">      
                                <input :required="true" v-else v-mask="'########'" @blur="getAdressByCEP(cep)" v-model="cep" type="text" class="form-control-sm form-control" id="inputEmail4" placeholder="CEP">      
                            </div>

                             <div class="pt-2 d-flex flex-column align-items-start form-group  col-md-6">
                                <label for="inputEmail4">Rua:</label>
                                <input disabled v-if="companyExists.rua" :value="companyExists.rua" type="text" class="form-control-sm form-control" id="inputEmail4" placeholder="Rua">      
                                <input :required="true" readonly v-else :value="address.rua" type="text" class="form-control-sm form-control" id="inputEmail4" placeholder="Rua">      
                            </div>
                            <div class="pt-2 d-flex flex-column align-items-start form-group col-md-2"> 
                                <label for="inputEmail4">Número:</label>
                                <input disabled v-if="companyExists.numero" :value="companyExists.numero" type="text" class="form-control-sm form-control" id="inputEmail4" placeholder="Numero">      
                                <input :required="true" v-else v-model="address.numero" type="text"  class="form-control-sm form-control" id="inputEmail4" placeholder="Nº">      
                            </div>
                    </div>
                    <div class="row"> 
                            <div class="pt-2 d-flex flex-column align-items-start form-group col-md-5"> 
                                <label for="inputEmail4">Bairro:</label>
                                <input disabled v-if="companyExists.bairro" :value="companyExists.bairro" type="text" class="form-control-sm form-control" id="inputEmail4" placeholder="Bairro">      
                                <input :required="true" readonly v-else :value="address.bairro" type="text" class="form-control-sm form-control" id="inputEmail4" placeholder="Bairro">      
                            </div>
                            <div class="pt-2 d-flex flex-column align-items-start form-group  col-md-5">
                                <label for="inputEmail4">Cidade:</label>
                                <input disabled v-if="companyExists.cidade" :value="companyExists.cidade" type="text" class="form-control-sm form-control" id="inputEmail4" placeholder="Cidade">      
                                <input :required="true" readonly v-else :value="address.cidade" type="text" class="form-control-sm form-control" id="inputEmail4" placeholder="Cidade">                              
                            </div>
                           <div class="pt-2 d-flex flex-column align-items-start form-group col-md-2">
                                <label for="inputEmail4">Estado:</label>
                                <input disabled v-if="companyExists.estado" :value="companyExists.estado" type="text" class="form-control-sm form-control" id="inputEmail4" placeholder="Uf">      
                                <input :required="true" readonly v-else type="text" :value="address.uf" class="form-control-sm form-control" id="inputEmail4" placeholder="UF">      
                            </div>
                
                    </div>
                </div>
                <div class="col">
                    <div class="row"> 
                                <div class="pt-1 col d-flex flex-column align-items-start form-group">
                                    <label for="inputEmail4">Valor contratado:</label>
                                    <money :required="true" type="text" class="form-control-sm form-control" v-model="new_contract.contracted_amount" v-bind="money"></money>
                                    <!-- <input v-model="new_contract.contracted_amount"  type="text" class="form-control-sm form-control" id="inputEmail4" placeholder="Valor contratado"> -->
                                </div>
                                <div class=" col d-flex flex-row align-items-center form-group justify-content-star mt-4">
                                    <div class="mx-4 form-check">
                                        <input disabled  v-model="new_contract.amount_type" class="form-check-input" type="radio" name="flexRadioDefault" value="0" id="zero" >
                                        <label class="form-check-label" for="zero">
                                            Real
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input v-model="new_contract.amount_type" class="form-check-input" type="radio" name="flexRadioDefault" value="1" id="one" >
                                        <label class="form-check-label" for="one">
                                            Litro
                                        </label>
                                    </div>
                                </div>
                            
                    </div>
                    <div class="row">
                                <div class=" d-flex flex-column align-items-start form-group col"> 
                                    <label for="inputEmail4">Data inicial:</label>
                                    <b-form-datepicker :required="true" v-model="new_contract.initialDate" :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }" style="min-width: 150px" placeholder="De" id="datepicker-start" size="sm" locale="br" ></b-form-datepicker>
                                </div>
                                <div class=" d-flex flex-column align-items-start form-group col"> 
                                    <label for="inputEmail4">Data final:</label>
                                    <b-form-datepicker :required="true" v-model="new_contract.endDate" :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }" style="min-width: 150px" placeholder="Até" id="datepicker-end" size="sm" locale="br" ></b-form-datepicker>
                                </div>
                    </div>
                    <div class="d-flex flex-column align-items-start form-group col-md-12">
                            <label for="inputEmail4">Observações:</label>
                            <textarea  v-model="new_contract.comments" type="text" class="form-control-sm form-control" id="inputEmail4" placeholder="Observações"></textarea>
                    </div>     
                </div>
            </div> 
            <div class="d-flex justify-content-end" >
                <button type="submit" @click="mountAddress()" class="m-3 btn btn-sm btn-primary">Gerar contrato</button>
            </div>
        </form>
      </div>
        <div class=" my-5 d-flex flex-row  justify-content-between">
      
      </div>
    </div>
</template>
<style scoped>
input[type=radio] {
    width: 14px;
    height: 14px;
}
</style>
<script>
    import axios from 'axios';
    axios.defaults.headers.common = {'Authorization': "Bearer " + sessionStorage.getItem("token")}
    export default{
        name:'ContentListDriver',

        data(){
        return {
              money: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: '',
                precision: 2,
                masked: false,
            },
            key:'',
            address: {
                bairro: '',
                rua: '',
                cidade: '',
                uf: '',
                numero: ''
            },
            cep: '',
            companies: {

            },
            dataResponse: {
                data:{
                    data:''
                }
            },
            companyExists: {
                nome:'',
                cnpj:'',
                email:'',
                telefone:'',
                cep:'',
                cidade:'',
                estado:'',
                rua:'',
                bairro:'',
                numero:'',

            },
            new_contract: {
                companyRegister: {
                        name: '',
                        cnpj:'',
                        email: '',
                        phone: '',
                        cep: '',
                        address: '',
                        city: '',
                        uf: ''
                    },
                company_id: '',
                station_id: this.$route.params.station_id,
                contracted_amount: '',
                amount_type: 1,
                comments:'',
                initialDate: '',
                endDate: ''
            }
        }
        }, methods: {
            sendRequest() {
              
            this.$http.post('station/generateContract', this.new_contract).then(response => {

                this.dataResponse = response
                this.newDriver = {}

                this.$router.push('/station/' + this.new_contract.station_id + '/' + this.$route.params.user_id)
                    
            })
                
                
            },

            getAdressByCEP(cep) {
                this.companyExists = {}
             
                axios.get('https://viacep.com.br/ws/' + cep + "/json/").then(response => {
                   this.address.cidade = response.data.localidade
                    this.address.uf = response.data.uf
                    this.address.bairro = response.data.bairro
                    this.address.rua = response.data.logradouro
                    
             
                })

            },

            fillCompanyInputs(index) {
                let companies = this.companies.data
                let companySelected = {}
                companies.forEach(function(company) {
                if(index == company.id) {
                            companySelected = company
                        
                        return companySelected
                    }
               
            })
            this.new_contract.company_id = companySelected.id
        
            this.companyExists.cep = companySelected['cep']
            this.companyExists.nome = companySelected['name']
            this.companyExists.cnpj = companySelected['cnpj']
            this.companyExists.email = companySelected['email']
            this.companyExists.telefone = companySelected['phone']
            this.companyExists.cidade = companySelected['city']
            this.companyExists.estado = companySelected['uf']

            let resultSplit = companySelected['address'].split(",", 3);
            this.companyExists.rua = resultSplit[0]
            this.companyExists.numero = resultSplit[1]
            this.companyExists.bairro = resultSplit[2]
           

            
               
            },

            mountAddress(){
                this.new_contract.companyRegister.cep = this.cep
                this.new_contract.companyRegister.address = this.address.rua +', '+ this.address.numero + ', ' + this.address.bairro
                this.new_contract.companyRegister.city = this.address.cidade
                this.new_contract.companyRegister.uf = this.address.uf
                return this.sendRequest()
            }
        
            }, mounted() {
           
                this.$http.get('company/getAllCompanies').then(response => {

                this.companies = response.data
               
                
                })
            
        }
    }

</script>