
import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import VueMask from 'v-mask'
import money from 'v-money'
import store from './store'
import VueExcelXlsx from 'vue-excel-xlsx';
import api from './configs/api'

Vue.prototype.$http = api
Vue.config.productionTip = false

Vue.use(VueExcelXlsx);

Vue.use(money, {precision: 4})
Vue.use(VueMask);


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
