<template>
      <div class="text-sm container-xxl my-4">
        <div class="table-responsive shadow rounded bg-white">
          <div class="border py-3 d-flex flex-row  justify-content-between">
              <h3 class="px-3 card-title">Frentistas</h3>
              <button type="button"  @click="routerPush()" class="mx-3 btn btn-sm btn-success">Novo frentista</button>
          </div>
          <div v-if="this.dataResponse.data.status == 'success'"  class="alert alert-success" role="alert">
                {{this.dataResponse.data.data}}
          </div>
          <div v-if="this.dataResponse.data.status == 'erro'"  class="alert alert-danger" role="alert">
                {{this.dataResponse.data.data}}
          </div>
            <b-skeleton-wrapper :loading="loading">
              <template #loading>
                <b-card style="border: none !important">
                  <b-skeleton-table
                    :rows="5"
                    :columns="8"
                    :table-props="{ bordered: true, striped: true }"
                  ></b-skeleton-table>
                </b-card>
              </template>
              <b-card style="border: none !important" > 
                <table  class="table-sm table-hover table-bordered table table-striped">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Nome</th>
                    <th scope="col">Telefone</th>
                    <th scope="col">Email</th>
                    <th scope="col">CPF</th>
                    <th scope="col">Endereço</th>
                    <th scope="col">Status</th>
                    <th scope="col"> Editar </th>
                    <th scope="col"> Excluir </th>
                  </tr>
                </thead>
                <tbody>
              <tr class="tablefontattendant" v-for="(attendant, index) in attendants" :key="index">
                    <th scope="row">{{attendant.id}}</th>
                      <td>{{attendant.name}}</td>
                      <td>{{attendant.phone}}</td>
                      <td>{{attendant.email}}</td>
                      <td>{{attendant.cpf}}</td>
                      <td>{{attendant.address}}</td>
                      <td v-if="attendant.status == 1">Ativo</td>
                      <td v-if="attendant.status == 0">Inativo</td>
                      <td><button @click="routerPushEdit(attendant.id)" class="btn btn-warning btn-sm">Editar</button></td>
                      <td><button @click="deleteAttendant(attendant.id)" class="btn btn-danger btn-sm">Excluir</button></td>
                  </tr>
                  
                </tbody>
              </table>
              </b-card>
            </b-skeleton-wrapper>
        </div>
      </div>
</template>
<style scoped>
.tablefontattendant {
  font-size: 0.9em;
}
</style>
<script>
import axios from 'axios';
axios.defaults.headers.common = {'Authorization': "Bearer " + sessionStorage.getItem("token")}

export default{
    name:'ContentList',
    data(){
      return {
        loading: false,
        loadingTime: 0,
        maxLoadingTime: 2,
        stationId: this.$route.params.station_id,
        idUser: this.$route.params.user_id,
        attendants: {
        },
        dataResponse: {
          data:{
             data:''
          }
        },
      }
    },
     watch: {
      loading(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.clearLoadingTimeInterval()

          if (newValue) {
            this.$_loadingTimeInterval = setInterval(() => {
              this.loadingTime++
            }, 1000)
          }
        }
      },
      loadingTime(newValue, oldValue) {
        if (newValue !== oldValue) {
          if (newValue === this.maxLoadingTime) {
            this.loading = false
          }
        }
      }
    },
    created() {
      this.$_loadingTimeInterval = null
    },

    mounted(){
        this.startLoading(),
        this.$http.get('station/getAllAttendantsByStationId/' + this.stationId ).then(resposta => {
         
          if(resposta.data.status == "success"){
            this.attendants = resposta.data.data;
          }
          if(resposta.data.status == "erro"){
            this.dataResponse = resposta
          }

        })
      
      
    },
    
    methods: {
      clearLoadingTimeInterval() {
        clearInterval(this.$_loadingTimeInterval)
        this.$_loadingTimeInterval = null
      },
      startLoading() {
        this.loading = true
        this.loadingTime = 0
      },
      routerPush() {
        this.$router.push('/newattendant/' + this.stationId + '/' + this.idUser)
      },
      deleteAttendant(id) {
          this.$http.get('station/deleteAttendantById/' + id).then(resposta => {
          this.dataResponse = resposta
           this.$http.get('station/getAllAttendantsByStationId/' + this.stationId ).then(resposta => {
          
            if(resposta.data.status == "success"){
              this.attendants = resposta.data.data;
            }
            if(resposta.data.status == "erro"){
              this.dataResponse = resposta
            }

          })
        })
      },
      routerPushEdit(attendantId) {
        this.$router.push('/editattendant/' + this.stationId + '/' + attendantId)
      },
    }
     
  }
</script>