import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import DriversView from '../views/DriversView.vue'
import NewDriverView from '../views/NewDriverView.vue'
import NewVehicleView from '../views/NewVehicleView.vue'
import AssociateVehicleView from '../views/AssociateVehicleView.vue'
import HistoricView from '../views/HistoricView.vue'
import Dashboard from '../views/DashboardView.vue'
import PostoView from '../views/PostoView.vue'
import StationHistoric from '../views/StationHistoricView.vue'
import Attendent from '../views/AttendentsView.vue'
import NewAttendant from '../views/NewAttendantView.vue'
import EditAttendant from '../views/EditAttendantView.vue'
import EditDriver from '../views/EditDriverView.vue'
import GenerateContract from '../views/GenerateContractView.vue'
import TheTables from'../views/TheTablesView.vue'

Vue.use(VueRouter)
const routes = [
  
  {
    path: '/veiculo/:company_id/:user_id',
    name: 'veiculo',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/:company_id/:user_id',
    name: 'dashboard',
    component: Dashboard
  },
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/motorista/:company_id/:user_id',
    name: 'motorista',
    component: DriversView
  },
  {
    path: '/cadastro_motorista/:company_id/:user_id',
    name: 'cadastroMotorista',
    component: NewDriverView
  },
  {
    path: '/cadastro_veiculo/:company_id/:user_id',
    name: 'cadastroVeiculo',
    component: NewVehicleView
  },
  {
    path: '/associa_veiculo/:driver_id/:name/:company_id/:user_id',
    name: 'AssociateVehicleView',
    component: AssociateVehicleView
  },
  {
    path: '/historico/:company_id/:user_id/:contract_id',
    name: 'HistoricView',
    component: HistoricView
  },
  {
    path: '/station/:station_id/:user_id',
    name: 'PostoView',
    component: PostoView
  },
  {
    path: '/station/historic/:station_id/:user_id/:company_id/:contract_id',
    name: 'StationHistoric',
    component: StationHistoric
  },
  {
    path: '/attendent/:station_id/:user_id/',
    name: 'Attendant',
    component: Attendent
  },
  {
    path: '/newattendant/:station_id/:user_id/',
    name: 'NewAttendant',
    component: NewAttendant
  },
  {
    path: '/editattendant/:station_id/:attendant_id/',
    name: 'EditAttendant',
    component: EditAttendant
  },
  {
    path: '/editdriver/:station_id/:driver_id/',
    name: 'EditDriver',
    component: EditDriver
  },
  {
    path: '/generatecontract/contract/:station_id/:user_id',
    name: 'GenerateContract',
    component: GenerateContract
  },
  {
    path: '/tables',
    name: 'TheTables',
    component: TheTables
  }
]

const router = new VueRouter({
  mode:'history',
  routes
})

export default router
