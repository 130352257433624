<template  >
    <div >
        <div class=" container text-center">
            <div class=" py-5 row justify-content-sm-center">
                <div style="width: 30% !important; background: #FFFF !important" class="mt-5  p-5 border col-md-3">
                    <div class="my-3">
                       <img class="logo" src="../assets/PagPosto.png" alt="">
                    </div>
                      
                        <div class="mb-3 d-flex flex-column align-items-start " >
                            <label for="exampleInputEmail1" class="form-label">Login:</label>
                            <input v-mask="'(##) # ####-####'" v-model="userData.phone" type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
                        </div>
                        <div class="mb-3 d-flex flex-column align-items-start ">
                            <label for="exampleInputPassword1" class="form-label">Senha:</label>
                            <input v-model="userData.password" type="password" maxlength="10" class="form-control" id="exampleInputPassword1">
                        </div>
                        <button style="width: 100%" @click="logar()" class="btn btn-sm btn-primary">Entrar</button>
                        <div v-if="this.erro"  class="my-3 text text-danger" role="alert">
                            {{this.erro}} Tente novamente.
                        </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.logo {
    width: 100%;
    display: block;
    margin: 0 auto;
    margin-left: 5px;
}
</style>
<script>
import axios from 'axios';
export default {

    data(){
        return {
         
            userData: {
                phone: '',
                password: ''
            },

            erro: ''
        }
    },

    methods: {
        logar() {

            var phone = this.userData.phone.replace('(','');
            var phoneReplace = phone.replace(')','');
            var phoneReReplace = phoneReplace.replace(' ','');
            var phoneReReReplace = phoneReReplace.replace('-', '');
            var phoneReReReReplace = phoneReReReplace.replace(' ', '');
            this.userData.phone = phoneReReReReplace
            
              this.$http.post('/auth/login', this.userData).then(response => {
             
                if(response.data.status == 'success') {
                    
                    var id = response.data.data.company_id;
                    var user_id = response.data.data.id;
                    var token = response.data.data.token;
                    var user_type = response.data.data.type;
                    var station_id = response.data.data.station_id;
                    
                    sessionStorage.setItem("token", token);
                    axios.defaults.headers.common = {'Authorization': "Bearer " + sessionStorage.getItem("token")}

                    if(user_type == 1) {
                        this.$router.push('/' + id + '/' + user_id)
                    } else if (user_type == 2) {
                         this.$router.push('/station/' + station_id + '/' + user_id)
                    }

                   
                
                } else if (response.data.status == 'erro') {
                    this.erro = response.data.data;
                }
            })
        }
    }

}
</script>